.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 900;
  top:0;
  left:0;
  background: #071c2c;
  opacity: 0.5;
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 910;
}

.overlay-spinner {
  margin: 0.625rem auto;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  border: 0.25rem solid #ffffff;
  border-top: 0.25rem solid #e73c33;
  animation: spin 1.5s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}