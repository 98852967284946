#dashboardprime > div.top {
  top: 5px;
}

#dashboardmainmiddle {
  overflow-y: auto;
  margin: auto 24% auto 10.1%;
}

.full-width#dashboardmainmiddle{
  margin: auto 0% auto 8.1%;
}

#dashboardprimeextra {
  top: 0px;
}

.extramainpanel > div.fields > div.field2 {
  padding: 10px 10px;
}

.extramainpanel > div.title {
  padding-top: 13px;
}
