@font-face {
    font-family: 'hurme1thin';
    src: url('./Fonts/Gilroy/Gilroy-Thin.otf') format('opentype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'hurme1thinob';
    src: url('./Fonts/Gilroy/Gilroy-ThinItalic.otf') format('opentype');
    font-weight: normal;
    font-style: normal;

}



@font-face {
    font-family: 'hurme1black';
    src: url('./Fonts/Gilroy/Gilroy-Black.otf') format('opentype');
    src: local('/media/Gilroy/Gilroy-Black.otf') format('opentype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'hurme1blackob';
    src: url('./Fonts/Gilroy/Gilroy-BlackItalic.otf') format('opentype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'hurme1bold';
    src: url('./Fonts/Gilroy/Gilroy-Bold.otf') format('opentype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'hurme1boldob';
    src: url('./Fonts/Gilroy/Gilroy-BoldItalic.otf') format('opentype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'hurme1hairline';
    src: url('./Fonts/Gilroy/Gilroy-UltraLight.otf') format('opentype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'hurme1hairlineob';
    src: url('./Fonts/Gilroy/Gilroy-UltraLightItalic.otf') format('opentype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'hurme1light';
    src: url('./Fonts/Gilroy/Gilroy-Light.otf') format('opentype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'hurme1lightob';
    src: url('./Fonts/Gilroy/Gilroy-LightItalic.otf') format('opentype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'hurme1ob';
    src: url('./Fonts/Gilroy/Gilroy-RegularItalic.otf') format('opentype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'hurme1regular';
    src: url('./Fonts/Gilroy/Gilroy-Regular.otf') format('opentype');
    src: local('/media/Gilroy/Gilroy-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'hurme1semibold';
    src: url('./Fonts/Gilroy/Gilroy-Semibold.otf') format('opentype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'hurme1semiboldob';
    src: url('./Fonts/Gilroy/Gilroy-SemiboldItalic.otf') format('opentype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'hurme1medium';
    src: url('./Fonts/Gilroy/Gilroy-Medium.otf') format('opentype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'hurme1mediumob';
    src: url('./Fonts/Gilroy/Gilroy-MediumItalic.otf') format('opentype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'hurme1heavy';
    src: url('./Fonts/Gilroy/Gilroy-Heavy.otf') format('opentype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'hurme1heavyob';
    src: url('./Fonts/Gilroy/Gilroy-HeavyItalic.otf') format('opentype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'hurme1extrabold';
    src: url('./Fonts/Gilroy/Gilroy-Extrabold.otf') format('opentype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'hurme1extraboldob';
    src: url('./Fonts/Gilroy/Gilroy-ExtraboldItalic.otf') format('opentype');
    font-weight: normal;
    font-style: normal;

}