@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

html,
body {
  margin: 0px;
  padding: 0px;
  clear: both;
  -webkit-font-smoothing: antialiased;
  font-smoothing: always;
  height: 100%;
  min-height: 100%;
  font-family: 'hurme1regular', sans-serif;
  font-size: 18px;
  text-rendering: optimizeLegibility;
  /*    -webkit-animation-name: fade-in;
    -webkit-animation-duration: 1.5s;*/
}
img {
  outline: none;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; /* For some Androids */
}

.cursorPointer {
  cursor: pointer;
}

.infinite_rotate {
  animation: infinite_rotation 1000ms infinite linear;
}
@keyframes infinite_rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.blackspan {
  color: #000000;
}
.blackspan2 {
  color: #222222;
}
.blackspan1 {
  color: #111111;
}
.blackspan56 {
  color: #565656;
}
.blackspan65 {
  color: #656565;
}
.blackspan7 {
  color: #777777;
}
.blackspan3 {
  color: #333333;
}
.blackspan4 {
  color: #444444;
}
.blackspan8 {
  color: #888888;
}
.blackspan9 {
  color: #999999;
}
.disabledDiv{
  opacity: 0.4;
}
.ebonyclayspan {
  color: #22313f;
}
.bluespan {
  color: #6fc1d2;
}
.bluespan1 {
  color: #428e9e;
}
.peterriverspan {
  color: #3498db;
}
.pictonbluespan {
  color: #22a7f0;
}
.pinkspan {
  color: #dd6bae;
}
.pinkspan1 {
  color: #a0497c;
}
.amethystspan {
  color: #9b59b6;
}
.wisteriaspan {
  color: #8e44ad;
}
.yellowspan {
  color: #dfbc42;
}
.yellowspan1 {
  color: #9d8222;
}
.sunflowerspan {
  color: #f1c40f;
}
.orangespan {
  color: #f39c12;
}
.carrotspan {
  color: #e67e22;
}
.pumpkinspan {
  color: #d35400;
}
.greenspan {
  color: #7dc692;
}
.greenspan1 {
  color: #508b61;
}
.emerlandspan {
  color: #2ecc71;
}
.nephritisspan {
  color: #27ae60;
}
.greenseaspan {
  color: #16a085;
}
.redspan {
  color: #e65d5d;
}
.redspan1 {
  color: #c43434;
}
.alizarinspan {
  color: #e74c3c;
}
.pomegranatespan {
  color: #c0392b;
}
.brownspan {
  color: #d98e64;
}
.brownspan1 {
  color: #a06849;
}
.whitespan {
  color: #ffffff;
}
.whitespan1 {
  color: #fcfcfc;
}
.whitespan2 {
  color: #f9f9f9;
}
.whitespan5 {
  color: #f5f5f5;
}
.e8span {
  color: #e8e8e8;
}
.ddspan {
  color: #dddddd;
}
.aaspan {
  color: #aaaaaa;
}
.bbspan {
  color: #bbbbbb;
}
.ccspan {
  color: #cccccc;
}
.eespan {
  color: #eeeeee;
}

.midnightbluespan {
  color: #2c3e50;
}
.wetasphaltspan {
  color: #34495e;
}
.silverspan {
  color: #bdc3c7;
}
.asbestosspan {
  color: #7f8c8d;
}
.concretespan {
  color: #95a5a6;
}
.belizeholespan {
  color: #2980b9;
}
.turquoisespan {
  color: #1abc9c;
}
.orangespan {
  color: #ff5e00;
}
.dogreenspan {
  color: #47b45c;
}

.transparentspan {
  color: transparent;
}

.colorcommutepotentialspan {
  color: #616161;
}
.colorcommuteavailablespan {
  color: #085078;
}
.colorcommutedeclinedspan {
  color: #8d000f;
}

.colorprim50span {
  color: #fce8e7;
}
.colorprim100span {
  color: #f8c5c2;
}
.colorprim200span {
  color: #f39e99;
}
.colorprim300span {
  color: #ee7770;
}
.colorprim400span {
  color: #eb5952;
}
.colorprim500span {
  color: #e73c33;
}
.colorprim600span {
  color: #e4362e;
}
.colorprim700span {
  color: #e02e27;
}
.colorprim800span {
  color: #dd2720;
}
.colorprim900span {
  color: #d71a14;
}
.colorprimA100span {
  color: #ffffff;
}
.colorprimA200span {
  color: #ffd5d4;
}
.colorprimA400span {
  color: #ffa3a1;
}
.colorprimA700span {
  color: #ff8a87;
}

.colorprimlight50span {
  color: #fce8e7;
}
.colorprimlight100span {
  color: #f8c5c2;
}
.colorprimlight200span {
  color: #f39e99;
}
.colorprimlight300span {
  color: #ee7770;
}
.colorprimlight400span {
  color: #eb5952;
}
.colorprimlight500span {
  color: #e73c33;
}
.colorprimlight600span {
  color: #e4362e;
}
.colorprimlight700span {
  color: #e02e27;
}
.colorprimlight800span {
  color: #dd2720;
}
.colorprimlight900span {
  color: #d71a14;
}
.colorprimlightA100span {
  color: #ffffff;
}
.colorprimlightA200span {
  color: #ffd5d4;
}
.colorprimlightA400span {
  color: #ffa3a1;
}
.colorprimlightA700span {
  color: #ff8a87;
}

.colorsec50span {
  color: #ffeee0;
}
.colorsec100span {
  color: #ffe1c8;
}
.colorsec200span {
  color: #fed4b1;
}
.colorsec300span {
  color: #fec799;
}
.colorsec400span {
  color: #fdb67b;
}
.colorsec500span {
  color: #fda55d;
}
.colorsec600span {
  color: #f59547;
}
.colorsec700span {
  color: #ed8530;
}
.colorsec800span {
  color: #e5751a;
}
.colorsec900span {
  color: #dd6503;
}
.colorsecA100span {
  color: #ffe57f;
}
.colorsecA200span {
  color: #ffd740;
}
.colorsecA400span {
  color: #ffc400;
}
.colorsecA700span {
  color: #ffab00;
}

.colortert50span {
  color: #fbfefd;
}
.colortert100span {
  color: #fec9c3;
}
.colortert200span {
  color: #fdb5ab;
}
.colortert300span {
  color: #fda094;
}
.colortert400span {
  color: #fd8676;
}
.colortert500span {
  color: #fc6b58;
}
.colortert600span {
  color: #fb503a;
}
.colortert700span {
  color: #fb361c;
}
.colortert800span {
  color: #f42004;
}
.colortert900span {
  color: #d61c04;
}
.colortertA100span {
  color: #ffb6a5;
}
.colortertA200span {
  color: #ffa48f;
}
.colortertA400span {
  color: #ff8064;
}
.colortertA700span {
  color: #ff4923;
}

.colorquart50span {
  color: #ffeee0;
}
.colorquart100span {
  color: #ffe1c8;
}
.colorquart200span {
  color: #fed4b1;
}
.colorquart300span {
  color: #fec799;
}
.colorquart400span {
  color: #fdb67b;
}
.colorquart500span {
  color: #fda55d;
}
.colorquart600span {
  color: #f59547;
}
.colorquart700span {
  color: #ed8530;
}
.colorquart800span {
  color: #e5751a;
}
.colorquart900span {
  color: #dd6503;
}
.colorquartA100span {
  color: #ffe57f;
}
.colorquartA200span {
  color: #ffd740;
}
.colorquartA400span {
  color: #ffc400;
}
.colorquartA700span {
  color: #ffab00;
}

.fbmsngrspan {
  color: #448aff;
}
.facebookspan {
  color: #3b5998;
}
.linkedinspan {
  color: #007fb1;
}
.twitterspan {
  color: #39a9e0;
}
.googleplusspan {
  color: #d14836;
}
.tumblrspan {
  color: #2c4762;
}

.bgridecolor {
  background: #2980b9;
}

.bgfbmsngr {
  background: #448aff;
}
.bgfacebook {
  background: #3b5998;
}
.bglinkedin {
  background: #007fb1;
}
.bgtwitter {
  background: #39a9e0;
}
.bggoogleplus {
  background: #d14836;
}
.bgtumblr {
  background: #47b45c;
}

.bgcommutepotential {
  background: #616161;
}
.bgcommuteavailable {
  background: #085078;
}
.bgcommutedeclined {
  background: #8d000f;
}

.bgcolorprimlight50 {
  background: #fce8e7;
}
.bgcolorprimlight100 {
  background: #f8c5c2;
}
.bgcolorprimlight200 {
  background: #f39e99;
}
.bgcolorprimlight300 {
  background: #ee7770;
}
.bgcolorprimlight400 {
  background: #eb5952;
}
.bgcolorprimlight500 {
  background: #e73c33;
}
.bgcolorprimlight600 {
  background: #e4362e;
}
.bgcolorprimlight700 {
  background: #e02e27;
}
.bgcolorprimlight800 {
  background: #dd2720;
}
.bgcolorprimlight900 {
  background: #d71a14;
}
.bgcolorprimlightA100 {
  background: #ffffff;
}
.bgcolorprimlightA200 {
  background: #ffd5d4;
}
.bgcolorprimlightA400 {
  background: #ffa3a1;
}
.bgcolorprimlightA700 {
  background: #ff8a87;
}

.bgcolorprim50 {
  background: #fce8e7;
}
.bgcolorprim100 {
  background: #f8c5c2;
}
.bgcolorprim200 {
  background: #f39e99;
}
.bgcolorprim300 {
  background: #ee7770;
}
.bgcolorprim400 {
  background: #eb5952;
}
.bgcolorprim500 {
  background: #e73c33;
}
.bgcolorprim600 {
  background: #e4362e;
}
.bgcolorprim700 {
  background: #e02e27;
}
.bgcolorprim800 {
  background: #dd2720;
}
.bgcolorprim900 {
  background: #d71a14;
}
.bgcolorprimA100 {
  background: #ffffff;
}
.bgcolorprimA200 {
  background: #ffd5d4;
}
.bgcolorprimA400 {
  background: #ffa3a1;
}
.bgcolorprimA700 {
  background: #ff8a87;
}

.bgcolorsec50 {
  background: #ffeee0;
}
.bgcolorsec100 {
  background: #ffe1c8;
}
.bgcolorsec200 {
  background: #fed4b1;
}
.bgcolorsec300 {
  background: #fec799;
}
.bgcolorsec400 {
  background: #fdb67b;
}
.bgcolorsec500 {
  background: #fda55d;
}
.bgcolorsec600 {
  background: #f59547;
}
.bgcolorsec700 {
  background: #ed8530;
}
.bgcolorsec800 {
  background: #e5751a;
}
.bgcolorsec900 {
  background: #dd6503;
}
.bgcolorsecA100 {
  background: #ffe57f;
}
.bgcolorsecA200 {
  background: #ffd740;
}
.bgcolorsecA400 {
  background: #ffc400;
}
.bgcolorsecA700 {
  background: #ffab00;
}

.bgcolortert50 {
  background: #fbfefd;
}
.bgcolortert100 {
  background: #fec9c3;
}
.bgcolortert200 {
  background: #fdb5ab;
}
.bgcolortert300 {
  background: #fda094;
}
.bgcolortert400 {
  background: #fd8676;
}
.bgcolortert500 {
  background: #fc6b58;
}
.bgcolortert600 {
  background: #fb503a;
}
.bgcolortert700 {
  background: #fb361c;
}
.bgcolortert800 {
  background: #f42004;
}
.bgcolortert900 {
  background: #d61c04;
}
.bgcolortertA100 {
  background: #ffb6a5;
}
.bgcolortertA200 {
  background: #ffa48f;
}
.bgcolortertA400 {
  background: #ff8064;
}
.bgcolortertA700 {
  background: #ff4923;
}

.bgcolorquart50 {
  background: #ffeee0;
}
.bgcolorquart100 {
  background: #ffe1c8;
}
.bgcolorquart200 {
  background: #fed4b1;
}
.bgcolorquart300 {
  background: #fec799;
}
.bgcolorquart400 {
  background: #fdb67b;
}
.bgcolorquart500 {
  background: #fda55d;
}
.bgcolorquart600 {
  background: #f59547;
}
.bgcolorquart700 {
  background: #ed8530;
}
.bgcolorquart800 {
  background: #e5751a;
}
.bgcolorquart900 {
  background: #dd6503;
}
.bgcolorquartA100 {
  background: #ffe57f;
}
.bgcolorquartA200 {
  background: #ffd740;
}
.bgcolorquartA400 {
  background: #ffc400;
}
.bgcolorquartA700 {
  background: #ffab00;
}

.bgblack {
  background: #000000;
}
.bgblack2 {
  background: #222222;
}
.bgblack1 {
  background: #111111;
}
.bgblack56 {
  background: #565656;
}
.bgblack65 {
  background: #656565;
}
.bgblack7 {
  background: #777777;
}
.bgblack3 {
  background: #333333;
}
.bgblack4 {
  background: #444444;
}
.bgblack8 {
  background: #888888;
}
.bgblack9 {
  background: #999999;
}
.bgebonyclay {
  background: #22313f;
}
.bgdogreen {
  background: #47b45c;
}
.bgorange {
  background: #ff5e00;
}
.bgmidnightblue {
  background: #2c3e50;
}
.bgturquoise {
  background: #1abc9c;
}
.bggreensea {
  background: #16a085;
}
.bgblue {
  background: #6fc1d2;
}
.bgblue1 {
  background: #428e9e;
}
.bgbelizehole {
  background: #2980b9;
}
.bgtbelizehole {
  background: rgba(41, 128, 185, 0.7);
}
.bgpictonblue {
  background: #22a7f0;
}
.bgtpictonblue {
  background: rgba(34, 167, 240, 0.7);
}
.bgalizarin {
  background: #e74c3c;
}
.bgpink {
  background: #dd6bae;
}
.bgred {
  background: #e65d5d;
}
.bgred1 {
  background: #c43434;
}
.bgamethyst {
  background: #9b59b6;
}
.bgnephritis {
  background: #27ae60;
}
.bgwisteria {
  background: #8e44ad;
}
.bgyellow {
  background: #dfbc42;
}
.bgyellow1 {
  background: #9d8222;
}
.bggreen1 {
  background: #508b61;
}
.bggreen {
  background: #7dc692;
}
.bgpomegranate {
  background: #c0392b;
}
.bgbrown {
  background: #d98e64;
}
.bgbrown1 {
  background: #a06849;
}
.bgasbestos {
  background: #7f8c8d;
}
.bgwhite {
  background: #ffffff;
}
.bgwhite1 {
  background: #f9f9f9;
}
.bgwhite2 {
  background: #fcfcfc;
}
.bgwhite3 {
  background: #fafafa;
}
.bgwhite4 {
  background: #fbfbfb;
}
.bgwhite5 {
  background: #f5f5f5;
}
.bgwhite6 {
  background: #e8e8e8;
}
.bgwhite7 {
  background: #f2f2f2;
}

.leftalign {
  text-align: left;
}
.rightalign {
  text-align: right;
}
.centeralign {
  text-align: center;
}
.floatleft {
  float: left;
}
.floatright {
  float: right;
}
.clearfix {
  clear: both;
  overflow: hidden;
}
.verticalTopalign {
  vertical-align: top;
}
.verticalcenteralign {
  display: table-cell;
  vertical-align: middle;
}
.table {
  display: table;
}

.weight100 {
  font-family: 'hurme1thin';
}
.weight300 {
  font-family: 'hurme1light';
}
.weight400 {
  font-family: 'hurme1regular';
}
.weight500 {
  font-family: 'hurme1regular';
}
.weight600 {
  font-family: 'hurme1semibold';
}
.weight700 {
  font-family: 'hurme1bold';
}
.weight900 {
  font-family: 'hurme1black';
}
.weight100i {
  font-family: 'hurme1thinob';
}
.weight300i {
  font-family: 'hurme1lightob';
}
.weight400i {
  font-family: 'hurme1ob';
}
.weight500i {
  font-family: 'hurme1ob';
}
.weight600i {
  font-family: 'hurme1semiboldob';
}
.weight700i {
  font-family: 'hurme1boldob';
}
.weight900i {
  font-family: 'hurme1blackob';
}

.facebookspan {
  color: #3b5998;
}
.twitterspan {
  color: #39a9e0;
}
.googlespan {
  color: #245dc1;
}
.linkedinspan {
  color: #007fb1;
}

::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  -webkit-border-radius: 0px;
  border-radius: 0px;
  padding: 0px 1px;
  /*background: rgba(200,200,200,0.4);*/
  background: #333333;
}
/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0px;
  border-radius: 0px;
  width: 1px;
  background: #aaaaaa;

  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); */
}
::-webkit-scrollbar-thumb:window-inactive {
  /*	background: rgba(160,160,160,0.6); */
  background: #3498db;
}

.formerrors {
  position: absolute;
  z-index: 700;
  height: auto;
  width: auto;
  padding: 8px 10px;
  background: #fb361c;
  -moz-border-radius: 5px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(50, 50, 50, 0.3);
  moz-box-shadow: 0px 2px 5px rgba(50, 50, 50, 0.3);
  -webkit-box-shadow: 0px 2px 5px rgba(50, 50, 50, 0.3);
  font-size: 13px;
  color: #ffffff;
}

.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}
.relative {
  position: relative;
}

.border-box {
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

.shadow-box {
  box-shadow: 0px 2px 5px rgba(50, 50, 50, 0.3);
  moz-box-shadow: 0px 2px 5px rgba(50, 50, 50, 0.3);
  -webkit-box-shadow: 0px 2px 5px rgba(50, 50, 50, 0.3);
}

.shadow-box.dark {
  moz-box-shadow: 0px 2px 0px 0px #111111;
  -webkit-box-shadow: 0px 2px 0px 0px #111111;
  box-shadow: 0px 2px 0px 0px #111111;
  margin-bottom: 2px !important;
}

.shadow-text {
  text-shadow: 0 3px 1px rgba(0, 0, 0, 0.1);
}

div {
  /*	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;*/
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
  margin: 0 0 1rem;
}
h1 {
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: -1px;
}
h2 {
  font-size: 1.75rem;
  line-height: 2rem;
}
h3 {
  font-size: 1.5rem;
  line-height: 1.75rem;
}
h4 {
  font-size: 1.25rem;
  line-height: 1.5rem;
}
h5 {
  font-size: 0.9rem;
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
}
h1.large {
  font-size: 3.5rem;
  line-height: 4rem;
  letter-spacing: -2px;
}
a {
  text-decoration: none;
}
p {
  margin: 0 0 1rem;
}
p.7rem {
  font-size: 0.7rem;
}
p.8rem {
  font-size: 0.8rem;
}
span.7rem {
  font-size: 0.7rem;
}
span.8rem {
  font-size: 0.8rem;
}

.show_on_mobile {
  display: none !important;
}

.uppercased {
  text-transform: uppercase;
}
.new_Ul{
  padding-left: .2rem;
  margin: 0;
  border-top: none;
  background: white;
  position: absolute;
  z-index: 9;
  max-height: 350px;
  overflow: auto;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  font-size: smaller;
}
.dropdown-check-newList {
  text-transform: uppercase;
  list-style: none;
  width: 100%;
  height: 2rem;
  padding-top: 5px;
  border-bottom: 1px solid #e5e5e5;
}
.dropdown-check-listNew {
  padding-left: 5px;
  border: none;
  width: 100%;
  background: transparent;
  font-size: 16px;
  font-family: inherit;
  color: rgba(0, 0, 0, 0.87);
}
.selected-labelSpan {
  overflow: auto;
  white-space: nowrap;
  position: absolute;
  white-space: nowrap;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1;
  letter-spacing: 0.00938em;
  top: 4px;
  left: 12px;
  font-size: 11px;
  text-transform: uppercase;
  width: 95%;
}
.selected-labelSpan::-webkit-scrollbar {
  display: none;
}
.bgheading {
  display: table;
  font-size: 0.7rem;
  line-height: 1.7rem;
  padding: 0 0.8rem;
  -webkit-border-radius: 10px;
  moz-border-radius: 10px;
  border-radius: 10px;
}
.paymentOptionsDiv{
  display: flex;
  gap:6rem;
  padding: 20px;
  font-size:16px;
  align-items: center;
}
.toggleWid{
  width: 100px;
  margin-right: 5rem;
}

.heading2.aftericon.arrow:after {
  content: '\279C';
  font-size: 1.1rem;
  line-height: 1.3rem;
  padding-left: 10px;
  font-weight: 300;
}

.heading1 {
  display: table;
  font-size: 0.9rem;
  line-height: 1.1rem;
  padding: 0 0;
  margin-bottom: 1rem;
}
.heading2 {
  display: table;
  font-size: 1.1rem;
  line-height: 1.3rem;
  padding: 0 0;
  margin-bottom: 1.2rem;
}
.heading3 {
  display: table;
  font-size: 1.3rem;
  line-height: 1.5rem;
  padding: 0 0;
  margin-bottom: 1.3rem;
}
.para05 {
  font-size: 0.9rem;
  line-height: 1.1rem;
  padding: 0 0;
  margin-bottom: 0.9rem;
}
.para1 {
  font-size: 1rem;
  line-height: 1.2rem;
  padding: 0 0;
  margin-bottom: 1rem;
}
.para3 {
  font-size: 1.2rem;
  line-height: 1.4rem;
  padding: 0 0;
  margin-bottom: 1.2rem;
}
.para2 {
  font-size: 1.4rem;
  line-height: 1.6rem;
  padding: 0 0;
  margin-bottom: 1.4rem;
}

a.move {
  transition-property: transform;
  transition-duration: 0.15s;
}
a.move:hover {
  transform: translateY(-2px);
}

.buttonalt.move {
  transition-property: box-shadow, transform;
  transition-duration: 0.15s;
}
.buttonalt.move:hover {
  transform: translateY(-2px);
  box-shadow: 0 18px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07);
}

.card {
  padding: 20px 30px;
  /*  position: relative;*/
  z-index: 200;
  overflow: hidden;
  /*    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;*/
  border-radius: 5px;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
}
.card.move {
  transition-property: box-shadow, transform;
  transition-duration: 0.15s;
}
.card.move:hover {
  transform: translateY(-2px);
  box-shadow: 0 18px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07);
}

.btncard {
  border-radius: 5px;
  box-shadow: 0px 5px 10px rgba(50, 50, 50, 0.3);
}
.btncard.move {
  transition-property: box-shadow, transform;
  transition-duration: 0.15s;
}
.btncard.move:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 10px rgba(50, 50, 50, 0.3);
}

#starter_screen {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 840;
  background-image: linear-gradient(45deg, #e73c33, #b70900);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#starter_screen p {
  margin-top: 3rem;
  font-size: 2rem;
}

#containermain {
  margin-top: 60px;
  width: 100%;
  height: auto;
  text-align: center;
  clear: both;
}
#containermain1 {
  width: 100%;
  height: 100%;
  text-align: center;
  clear: both;
  overflow: hidden;
}
#containermainouter1 {
  width: 100%;
  text-align: center;
  clear: both;
  overflow: hidden;
  position: fixed;
  top: 60px;
  bottom: 0px;
}

#sidr-overlay {
  height: 100%;
  width: 100%;
  clear: both;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;
  background: rgba(10, 10, 10, 0.7);
  cursor: pointer;
}

.choicebox {
  display: table;
  table-layout: fixed;
  width: auto;
  height: 30px;
  line-height: 30px;
  clear: both;
  overflow: hidden;
  -moz-border-radius: 2px;
  border-radius: 2px;
  border: 1px solid #dddddd;
  font-size: 12px;
  font-family: 'hurme1regular', sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}
.choiceboxinner {
  display: table-cell;
  overflow: hidden;
  /*    background: #f9f9f9;*/
  padding: 0px 10px;
  color: #888888;
  cursor: pointer;
  -webkit-transition: background 0.6s, color 0.6s;
  transition: background 0.6s, color 0.6s;
}
.choiceboxinner:hover {
  background: #e8e8e8;
  color: #e73c33;
  -webkit-transition: background 0.6s, color 0.6s;
  transition: background 0.6s, color 0.6s;
}
.choiceboxinnerselected {
  background: #e73c33;
  color: #f9f9f9;
  -webkit-transition: background 0.6s, color 0.6s;
  transition: background 0.6s, color 0.6s;
}
.choiceboxinnerselected:hover {
  background: #e73c33;
  color: #f9f9f9;
  -webkit-transition: background 0.6s, color 0.6s;
  transition: background 0.6s, color 0.6s;
}

/*
.choicebox{
    display: table;
    table-layout: fixed;
    width: auto;
    height:30px;
    line-height: 30px;
    clear: both;
    overflow: hidden;
//    border: 1px solid #dddddd;
    font-size: 12px;
    text-transform: uppercase;
}
.choiceboxinner{
    display: table-cell;
    overflow: hidden;
//    background: #f9f9f9;
    -webkit-border-radius:5px;
    moz-border-radius:5px;
    border-radius: 5px;
    padding: 0px 10px;
    color: #aaaaaa;
    box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
    background: #f5f5f5;
    cursor: pointer;
    font-family:'hurme1regular', sans-serif;
    -webkit-transition:background 0.6s, color 0.6s;
    transition:background 0.6s, color 0.6s;
}
.choiceboxinner:hover{
    background: #FFFFFF;
    color:#439fe0;
    font-family:'hurme1bold', sans-serif;
    -webkit-transition:background 0.6s, color 0.6s;
    transition:background 0.6s, color 0.6s;
}
.choiceboxinnerselected{
    background: #FFFFFF;
    color:#439fe0;
    font-family:'hurme1bold', sans-serif;
    -webkit-transition:background 0.6s, color 0.6s;
    transition:background 0.6s, color 0.6s;
}
.choiceboxinnerselected:hover{
    background: #FFFFFF;
    color:#439fe0;
    font-family:'hurme1bold', sans-serif;
    -webkit-transition:background 0.6s, color 0.6s;
    transition:background 0.6s, color 0.6s;
}



.choiceboxmulti{
    display: table;
    table-layout: fixed;
    width: auto;
    height:30px;
    line-height: 30px;
    clear: both;
    overflow: hidden;
//    border: 1px solid #dddddd;
    font-size: 12px;
    text-transform: uppercase;
}
.choiceboxmultiinner{
    display: table-cell;
    overflow: hidden;
//    background: #f9f9f9;
    -webkit-border-radius:5px;
    moz-border-radius:5px;
    border-radius: 5px;

    padding: 0px 10px;

    color: #aaaaaa;
    box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
    background: #f5f5f5;
    cursor: pointer;
    font-family:'hurme1regular', sans-serif;
    -webkit-transition:background 0.6s, color 0.6s;
    transition:background 0.6s, color 0.6s;
}
.choiceboxmultiinner:hover{
    background: #FFFFFF;
    color:#439fe0;
    font-family:'hurme1bold', sans-serif;
    -webkit-transition:background 0.6s, color 0.6s;
    transition:background 0.6s, color 0.6s;
}
.choiceboxmultiinnerselected{
    background: #FFFFFF;
    color:#439fe0;
    font-family:'hurme1bold', sans-serif;
    -webkit-transition:background 0.6s, color 0.6s;
    transition:background 0.6s, color 0.6s;
}
.choiceboxmultiinnerselected:hover{
    background: #FFFFFF;
    color:#439fe0;
    font-family:'hurme1bold', sans-serif;
    -webkit-transition:background 0.6s, color 0.6s;
    transition:background 0.6s, color 0.6s;
}


.choiceboxmulti1{
    width: 100%;
    height:auto;
    clear: both;
    overflow: hidden;
    text-align: left;
}
.choiceboxmulti1innertitle{
    height: 30px;
    line-height: 30px;
    padding: 10px 10px;
    display: block;
    overflow: hidden;
    color:#565656;
    font-size: 13px;
    font-family:'hurme1bold', sans-serif;
}
.choiceboxmulti1inner{
    height: 30px;
    line-height: 30px;
    padding: 0px 10px;
    display: inline-block;
    overflow: hidden;
    margin-right: 10px;
    margin-bottom: 10px;
    color: #aaaaaa;
    box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
    background: #f5f5f5;
    cursor: pointer;
    -webkit-border-radius:5px;
    moz-border-radius:5px;
    border-radius: 5px;
//    border: 1px solid #dddddd;
    font-size: 12px;
    font-family:'hurme1regular', sans-serif;
    -webkit-transition:background 0.6s, color 0.6s;
    transition:background 0.6s, color 0.6s;
}
.choiceboxmulti1inner:hover{
    background: #FFFFFF;
    color:#439fe0;
    font-family:'hurme1bold', sans-serif;
    -webkit-transition:background 0.6s, color 0.6s;
    transition:background 0.6s, color 0.6s;
}
.choiceboxmulti1innerselected{
    background: #FFFFFF;
    color:#439fe0;
    font-family:'hurme1bold', sans-serif;
    -webkit-transition:background 0.6s, color 0.6s;
    transition:background 0.6s, color 0.6s;
}
.choiceboxmulti1innerselected:hover{
    background: #FFFFFF;
    color:#439fe0;
    font-family:'hurme1bold', sans-serif;
    -webkit-transition:background 0.6s, color 0.6s;
    transition:background 0.6s, color 0.6s;
}
*/

.choiceboxmulti {
  display: table;
  table-layout: fixed;
  width: auto;
  height: 30px;
  line-height: 30px;
  clear: both;
  overflow: hidden;
  -moz-border-radius: 2px;
  border-radius: 2px;
  border: 1px solid #dddddd;
  font-size: 12px;
  font-family: 'hurme1regular', sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}
.choiceboxmultiinner {
  display: table-cell;
  overflow: hidden;
  /*    background: #f9f9f9;*/
  padding: 0px 10px;
  color: #888888;
  cursor: pointer;
  -webkit-transition: background 0.6s, color 0.6s;
  transition: background 0.6s, color 0.6s;
}
.choiceboxmultiinner:hover {
  background: #e8e8e8;
  color: #e73c33;
  -webkit-transition: background 0.6s, color 0.6s;
  transition: background 0.6s, color 0.6s;
}
.choiceboxmultiinnerselected {
  background: #e73c33;
  color: #f9f9f9;
  -webkit-transition: background 0.6s, color 0.6s;
  transition: background 0.6s, color 0.6s;
}
.choiceboxmultiinnerselected:hover {
  background: #e73c33;
  color: #f9f9f9;
  -webkit-transition: background 0.6s, color 0.6s;
  transition: background 0.6s, color 0.6s;
}

.choiceboxmulti1 {
  width: 100%;
  height: auto;
  clear: both;
  overflow: hidden;
  text-align: left;
}
.choiceboxmulti1innertitle {
  height: 30px;
  line-height: 30px;
  padding: 10px 10px;
  display: block;
  overflow: hidden;
  color: #565656;
  font-size: 13px;
  font-family: 'hurme1bold', sans-serif;
  font-weight: 700;
}
.choiceboxmulti1inner {
  height: 30px;
  line-height: 30px;
  padding: 0px 10px;
  display: inline-block;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 10px;
  background: #f9f9f9;
  color: #888888;
  cursor: pointer;
  -moz-border-radius: 2px;
  border-radius: 2px;
  border: 1px solid #dddddd;
  font-size: 12px;
  font-family: 'hurme1regular', sans-serif;
  font-weight: 400;
  -webkit-transition: background 0.6s, color 0.6s;
  transition: background 0.6s, color 0.6s;
}
.choiceboxmulti1inner:hover {
  background: #e8e8e8;
  color: #e73c33;
  -webkit-transition: background 0.6s, color 0.6s;
  transition: background 0.6s, color 0.6s;
}
.choiceboxmulti1innerselected {
  background: #e73c33;
  color: #f9f9f9;
  -webkit-transition: background 0.6s, color 0.6s;
  transition: background 0.6s, color 0.6s;
}
.choiceboxmulti1innerselected:hover {
  background: #e73c33;
  color: #f9f9f9;
  -webkit-transition: background 0.6s, color 0.6s;
  transition: background 0.6s, color 0.6s;
}

input[type='url'],
input[type='text'],
input[type='tel'],
input[type='number'],
input[type='email'],
input[type='password'],
input[type='date'],
select,
textarea {
  font-size: 1.25rem;
  line-height: normal;
  padding: 0.75rem; /*border:1px solid #C5C5C5;*/
  border-style: none;
  border: none;
  resize: none;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  color: #565656;
  width: 100%;
  max-width: 100%;
  font-family: 'hurme1regular', sans-serif;
  margin: 0 0 0.5rem;
  /*	-webkit-transition:box-shadow 70ms ease-out,border-color 70ms ease-out;
	-moz-transition:box-shadow 70ms ease-out,border-color 70ms ease-out;
	transition:box-shadow 70ms ease-out,border-color 70ms ease-out;*/
  -moz-osx-font-smoothing: grayscale;
  box-shadow: 0px 2px 5px rgba(50, 50, 50, 0.3);
  height: auto;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  background: #f5f5f5;
}
input[type='url']::-ms-clear,
input[type='text']::-ms-clear,
input[type='date']::-ms-clear,
input[type='tel']::-ms-clear,
input[type='number']::-ms-clear,
input[type='email']::-ms-clear,
input[type='password']::-ms-clear,
select::-ms-clear,
textarea::-ms-clear {
  display: none;
}
input[type='file'] {
  font-size: 9pt;
  line-height: 9pt;
  width: auto;
  font-weight: 400;
  font-family: sans-serif;
}
.no_touch input:hover,
.no_touch select:hover,
.no_touch textarea:hover {
  border-color: #9cc9ff;
}
.focus,
input[type='url']:active,
input[type='url']:focus,
input[type='text']:active,
input[type='text']:focus,
input[type='date']:active,
input[type='date']:focus,
input[type='number']:active,
input[type='number']:focus,
input[type='email']:active,
input[type='email']:focus,
input[type='password']:active,
input[type='password']:focus,
select:active,
select:focus,
textarea:active,
textarea:focus {
  /*border-color:#9cc9ff;box-shadow:0 0 7px rgba(109,57,108,.15);*/
  outline-offset: 0;
  outline: 0;
  background: #ffffff;
}
input.borderless,
input.borderless:active,
input.borderless:focus {
  border: none;
  box-shadow: none;
}
/*input[type=text].ts_icon_placeholder::-webkit-input-placeholder{font-family:slack;position:relative;top:-.0625rem}
input[type=text].ts_icon_placeholder:-moz-placeholder{font-family:slack;position:relative;top:-.0625rem}
input[type=text].ts_icon_placeholder::-moz-placeholder{font-family:slack;position:relative;top:-.0625rem}
input[type=text].ts_icon_placeholder:-ms-input-placeholder{font-family:slack;position:relative;top:-.0625rem}*/
input[data-plastic-type='date'] ~ div .pickmeup {
  margin-bottom: 4rem;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 1;
}
.plastic_date_picker.pickmeup .pmu-button.pmu-month,
.plastic_date_picker.pickmeup .pmu-button.pmu-next,
.plastic_date_picker.pickmeup .pmu-button.pmu-prev {
  color: #fff;
  background-color: #2ab27b;
}
.plastic_date_picker.pickmeup .pmu-button.pmu-month {
  margin: 0 0.125rem;
}
input[readonly] {
  cursor: default;
  background: #fff;
}
input.input_inline {
  width: auto;
}
input.small,
select.small {
  font-size: 1rem;
  padding: 0.45rem 0.75rem 0.55rem;
}
select.small {
  padding-top: 0.35rem;
  padding-bottom: 0.45rem;
}

input[type='url'].tiny,
input[type='text'].tiny,
input[type='date'].tiny,
input[type='tel'].tiny,
input[type='number'].tiny,
input[type='email'].tiny,
input[type='password'].tiny,
select.tiny {
  font-size: 0.6rem;
  padding: 0.35rem 0.75rem 0.45rem;
}
select.tiny {
  padding-top: 0.25rem;
  padding-bottom: 0.35rem;
}

input.mini {
  font-size: 1rem;
  padding: 0.45rem 0.75rem 0.55rem;
  width: 84px;
}
textarea {
  font-size: 1rem;
  line-height: 1.25rem;
  width: 100%;
  height: 81pt;
  padding: 0.75rem 0.5rem 0.75rem 0.75rem;
  vertical-align: top;
}
textarea.small {
  padding: 0.5rem;
}

@-moz-document url-prefix() {
  .plastic_row .description {
    word-break: break-all;
  }
}

::-webkit-input-placeholder {
  color: #aaaaaa;
}
:-moz-placeholder {
  color: #aaaaaa;
}
::-moz-placeholder {
  color: #aaaaaa;
}
:-ms-input-placeholder {
  color: #aaaaaa;
}
input:disabled,
input:disabled:active,
select:disabled,
textarea:disabled {
  background: #f9f9f9;
  border-color: #c5c5c5 !important;
  box-shadow: none;
  color: #9e9e9e;
}
.checkbox input[type='checkbox'],
.radio input[type='radio'] {
  float: none;
}
.error input,
.error select,
.error textarea {
  border: 1px solid #cb5234;
  background: #fff6f6;
}
.error input:focus,
.error select:focus,
.error textarea:focus {
  border-color: #cb5234;
  box-shadow: 0 0 7px rgba(255, 0, 0, 0.15);
}
select {
  background: #fff;
  padding-right: 3rem;
}
select.small {
  padding-right: 2.5rem;
}
select.tiny {
  padding-right: 2rem;
}

input.dark[type='url'],
input.dark[type='text'],
input.dark[type='tel'],
input.dark[type='number'],
input.dark[type='email'],
input.dark[type='password'],
select.dark,
textarea.dark {
  font-size: 1.25rem;
  line-height: normal;
  padding: 0.75rem;
  border: 1px solid #000000;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  color: #f9f9f9;
  width: 100%;
  max-width: 100%;
  font-family: 'hurme1regular', sans-serif;
  margin: 0 0 0.5rem;
  /*    -webkit-transition:box-shadow 70ms ease-out,border-color 70ms ease-out;
    -moz-transition:box-shadow 70ms ease-out,border-color 70ms ease-out;
    transition:box-shadow 70ms ease-out,border-color 70ms ease-out;*/
  -moz-osx-font-smoothing: grayscale;
  box-shadow: none;
  height: auto;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  background-color: rgba(50, 50, 50, 0.8);
}
input.dark[type='url']::-ms-clear,
input.dark[type='text']::-ms-clear,
input.dark[type='tel']::-ms-clear,
input.dark[type='number']::-ms-clear,
input.dark[type='email']::-ms-clear,
input.dark[type='password']::-ms-clear,
select.dark::-ms-clear,
textarea.dark::-ms-clear {
  display: none;
}
input.dark[type='file'] {
  font-size: 9pt;
  line-height: 9pt;
  width: auto;
  font-weight: 400;
  font-family: sans-serif;
}
.no_touch input.dark:hover,
.no_touch select.dark:hover,
.no_touch textarea.dark:hover {
  border-color: #9cc9ff;
}
.focus,
input.dark[type='url']:active,
input.dark[type='url']:focus,
input.dark[type='text']:active,
input.dark[type='text']:focus,
input.dark[type='number']:active,
input.dark[type='number']:focus,
input.dark[type='email']:active,
input.dark[type='email']:focus,
input.dark[type='password']:active,
input.dark[type='password']:focus,
select.dark:active,
select.dark:focus,
textarea.dark:active,
textarea.dark:focus {
  border-color: #9cc9ff;
  box-shadow: 0 0 7px rgba(109, 57, 108, 0.15);
  outline-offset: 0;
  outline: 0;
}
input.dark.borderless,
input.dark.borderless:active,
input.dark.borderless:focus {
  border: none;
  box-shadow: none;
}
/*input.dark[type=text].ts_icon_placeholder::-webkit-input-placeholder{font-family:slack;position:relative;top:-.0625rem}
input.dark[type=text].ts_icon_placeholder:-moz-placeholder{font-family:slack;position:relative;top:-.0625rem}
input.dark[type=text].ts_icon_placeholder::-moz-placeholder{font-family:slack;position:relative;top:-.0625rem}
input.dark[type=text].ts_icon_placeholder:-ms-input-placeholder{font-family:slack;position:relative;top:-.0625rem}*/
input.dark[data-plastic-type='date'] ~ div .pickmeup {
  margin-bottom: 4rem;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 1;
}
.plastic_date_picker.pickmeup.dark .pmu-button.pmu-month,
.plastic_date_picker.pickmeup.dark .pmu-button.pmu-next,
.plastic_date_picker.pickmeup.dark .pmu-button.pmu-prev {
  color: #fff;
  background-color: #2ab27b;
}
.plastic_date_picker.pickmeup.dark .pmu-button.pmu-month {
  margin: 0 0.125rem;
}
input.dark[readonly] {
  cursor: default;
  background: rgba(50, 50, 50, 0.8);
}
input.input_inline.dark {
  width: auto;
}
input.small.dark,
select.small.dark {
  font-size: 1rem;
  padding: 0.45rem 0.75rem 0.55rem;
}
@-moz-document url-prefix() {
  .plastic_row .description.dark {
    word-break: break-all;
  }
  select.small.dark {
    padding-top: 0.35rem;
    padding-bottom: 0.45rem;
  }
}
input.tiny.dark,
select.tiny.dark {
  font-size: 0.6rem;
  padding: 0.35rem 0.75rem 0.45rem;
}
select.tiny.dark {
  padding-top: 0.25rem;
  padding-bottom: 0.35rem;
}

input.mini.dark {
  font-size: 1rem;
  padding: 0.45rem 0.75rem 0.55rem;
  width: 84px;
}
textarea.dark {
  font-size: 1rem;
  line-height: 1.25rem;
  width: 100%;
  height: 81pt;
  padding: 0.75rem 0.5rem 0.75rem 0.75rem;
  vertical-align: top;
}
textarea.small.dark {
  padding: 0.5rem;
}
input.dark::-webkit-input-placeholder,
textarea.dark::-webkit-input-placeholder {
  color: #656565;
}
input.dark:-moz-placeholder,
textarea.dark:-moz-placeholder {
  color: #656565;
}
input.dark::-moz-placeholder,
textarea.dark::-moz-placeholder {
  color: #656565;
}
input.dark:-ms-input-placeholder,
textarea.dark:-ms-input-placeholder {
  color: #656565;
}
input.dark:disabled,
input.dark:disabled:active,
select.dark:disabled,
textarea.dark:disabled {
  background: #f9f9f9;
  border-color: #c5c5c5 !important;
  box-shadow: none;
  color: #9e9e9e;
}
.checkbox input.dark[type='checkbox'],
.radio input.dark[type='radio'] {
  float: none;
}
.error input.dark,
.error select.dark,
.error textarea.dark {
  border: 1px solid #cb5234;
  background: #fff6f6;
}
.error input.dark:focus,
.error select.dark:focus,
.error textarea.dark:focus {
  border-color: #cb5234;
  box-shadow: 0 0 7px rgba(255, 0, 0, 0.15);
}
select.dark {
  background: rgba(50, 50, 50, 0.8);
  padding-right: 3rem;
}
select.small.dark {
  padding-right: 2.5rem;
}
select.tiny.dark {
  padding-right: 2rem;
}

label {
  font-weight: 400;
  margin: 0 0 0.25rem;
  display: inline-block;
  font-size: 1rem;
}
label.checkbox,
label.radio {
  padding-left: 1.5rem;
  cursor: pointer;
}
label.checkbox input[type='radio'],
label.checkbox input[type='checkbox'],
label.radio input[type='radio'],
label.radio input[type='checkbox'] {
  margin: -2px 0.4rem 0 -1.5rem;
  vertical-align: middle;
}
label.disabled {
  color: #9e9e9e;
}
label select {
  margin-top: 0.25rem;
}
label select::-ms-expand {
  display: none;
}
label.select {
  position: relative;
}
label.select:after {
  content: '\E271';
  font-family: Slack;
  position: absolute;
  right: 22px;
  bottom: 22px;
  font-size: 20px;
  pointer-events: none;
}
label.select.with_hint:after {
  bottom: 14px;
}
label.select.with_hint.small:after {
  bottom: 6px;
}
label.select.disabled:after {
  color: #9e9e9e;
}
label.select.small:after {
  right: 14px;
  bottom: 14px;
  font-size: 20px;
}

.btnyes,
.btnno {
  width: 80px;
}

.btn {
  line-height: 1.2rem;
  font-family: 'hurme1bold', sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  cursor: pointer;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 0.25rem;
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  text-align: center;
  white-space: nowrap;
  margin: 0;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  padding: 8px 14px 9px;
  font-size: 15px;
  text-transform: uppercase;
  margin: 2px;
}
.btn:after {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0.25rem;
}
/*a.btn{color:#ffffff;}*/
.btn.hover,
.btn:focus,
.btn:hover {
  outline: 0;
  text-decoration: none;
}
.no_touch .btn.hover,
.no_touch .btn:focus,
.no_touch .btn:hover {
  outline: 0;
  text-decoration: none;
}

.btn.hover:after,
.btn:focus:after,
.btn:hover:after {
  box-shadow: inset 0 -2px rgba(0, 0, 0, 0.25);
}
.btn.active,
.btn:active {
  color: #ffffff;
}
.btn.active:after,
.btn:active:after {
  box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.2);
}
.btn_outline {
  background: #fbfbfa;
  color: #555459 !important;
  font-weight: 700;
  text-shadow: none;
}
.btn_outline:after {
  border: 1px solid #c7cacd;
}
.btn_outline.btn_transparent {
  background-color: transparent !important;
}
/*.btn_outline.btn_transparent:after{border:1px solid rgba(255,255,255,.5)}*/
.btn_outline.btn_transparent.active,
.btn_outline.btn_transparent.hover,
.btn_outline.btn_transparent:active,
.btn_outline.btn_transparent:focus,
.btn_outline.btn_transparent:hover {
  background: rgba(255, 255, 255, 0.9) !important;
}
.btn_outline.btn_transparent.active:after,
.btn_outline.btn_transparent.hover:after,
.btn_outline.btn_transparent:active:after,
.btn_outline.btn_transparent:focus:after,
.btn_outline.btn_transparent:hover:after {
  box-shadow: none;
  border-color: transparent;
}
.btn_outline.btn_transparent.active,
.btn_outline.btn_transparent:active {
  background-color: rgba(255, 255, 255, 0.8) !important;
}
.btn_outline.hover,
.btn_outline:focus,
.btn_outline:hover {
  background: #ffffff;
  color: #3aa3e3 !important;
}
.btn_outline.hover:after,
.btn_outline:focus:after,
.btn_outline:hover:after {
  box-shadow: none;
}
.btn_outline:active {
  color: #3aa3e3;
}
.btn_outline:active:after {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.2);
}
.btn_outline.active {
  color: #666 !important;
  margin-top: 0;
  font-weight: 900;
}
.btn.btn_outline.btn_danger,
.btn.btn_outline.btn_warning {
  background: #fbfbfa !important;
}
.btn.btn_outline.btn_danger:focus,
.btn.btn_outline.btn_danger:hover,
.btn.btn_outline.btn_warning:focus,
.btn.btn_outline.btn_warning:hover {
  background: #fff !important;
}
.btn.btn_outline.btn_warning:focus,
.btn.btn_outline.btn_warning:hover {
  color: #dfa941 !important;
}
.btn.btn_outline.btn_warning:focus:after,
.btn.btn_outline.btn_warning:hover:after {
  border-color: #dfa941;
}
.btn.btn_outline.btn_danger:focus,
.btn.btn_outline.btn_danger:hover {
  color: #cb5234 !important;
}
.btn.btn_outline.btn_danger:focus:after,
.btn.btn_outline.btn_danger:hover:after {
  border-color: #cb5234;
}
.btn.btn_outline.disabled,
.btn.btn_outline.disabled:hover {
  color: #9e9ea6 !important;
  background: #fff !important;
}
.btn.btn_border {
  background: 0 0;
  border: 2px solid;
}
.btn.btn_border:after {
  box-shadow: none;
}
.btn.disabled,
.btn.disabled:active,
.btn.disabled:hover,
.btn:disabled,
.btn:disabled:active,
.btn:disabled:hover {
  background-color: #9e9ea6 !important;
  opacity: 0.35;
  -moz-opacity: 0.35;
  -khtml-opacity: 0.35;
  box-shadow: none !important;
  pointer-events: none;
}
.btn_small {
  padding: 5px 10px;
  font-size: 14px;
}
.btn_small i:before {
  font-size: 15px;
}
.btn_tiny {
  padding: 3px 7px;
  font-size: 11px;
}
.btn_tiny i:before {
  font-size: 12px;
}
.btn_large {
  padding: 14px 2pc 1pc;
  font-size: 20px;
}
.btn_extra_large {
  font-size: 1.125rem;
  padding: 1.25rem;
}
.btn_expand {
  width: 100%;
  display: block;
}
@media only screen and (max-width: 768px) {
  .btn_large {
    padding: 14px 20px 1pc;
  }
  .btn_extra_large {
    padding: 0.9375rem 2rem;
  }
}
.btn_info {
  background: #3aa3e3 !important;
}
.btn_warning {
  background: #dfa941 !important;
}
.btn_danger {
  background: #cb5234 !important;
}
.btn_twitter {
  background: #55acee !important;
}
.btn_facebook {
  background: #3b5998 !important;
}
.btn_transparent {
  background: rgba(255, 255, 255, 0.25) !important;
}
.btn.btn_success {
  background-color: #56b68b;
}
.btn.btn_success.disabled,
.btn.btn_success:disabled {
  background-color: #56b68b !important;
}
.btn i {
  margin-right: 0.25rem;
}
.btn i:before {
  font-size: inherit;
  vertical-align: bottom;
}
.btn_large i {
  margin-right: 0.5rem;
  font-size: 18px;
}
.btn_extra_large i {
  vertical-align: middle;
  font-size: 1.25rem;
}
.btn_with_icon {
  position: relative;
  padding-left: 3rem !important;
}
.btn_with_icon i {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 0.5rem 0.75rem;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  background: rgba(0, 0, 0, 0.2);
  display: inline-block;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  background-clip: padding-box;
  font-size: 1.4rem !important;
  line-height: 2.25rem;
}
.btn_icon {
  width: 2pc;
  height: 30px;
  padding: 0;
}
.btn_icon:before {
  display: block;
}
.btn.dropdown-toggle .caret {
  border-top-color: #fff;
}
.pill_btn {
  border-radius: 2rem;
  padding: 5px 13px 6px;
  font-weight: 600;
}
.pill_btn:after {
  border-radius: 2rem;
}
.pill_btn i {
  margin-right: 0.1rem;
}
.pill_btn.btn_small {
  padding: 3px 10px;
}
.pill_btn.btn_large {
  padding: 14px 2pc 1pc;
}
.pill_btn.btn_large i {
  margin-right: 0.4rem;
}
.btn_ios,
.btn_ios:focus,
.btn_ios:hover {
  width: 127px;
  height: 38px;
  background-image: url(/66f9/img/ios_app_store_badge.png);
  background-size: 127px 38px;
}
.btn_ios_large,
.btn_ios_large:focus,
.btn_ios_large:hover {
  width: 175px;
  height: 52px;
  background-image: url(/d1609/img/ios_app_store_badge_large.png);
  background-size: 175px 52px;
}
.btn_android,
.btn_android:focus,
.btn_android:hover {
  width: 110px;
  height: 38px;
  background-image: url(/66f9/img/google_play_badge.png);
  background-size: 110px 39px;
}
.btn_windows,
.btn_windows:focus,
.btn_windows:hover {
  width: 146px;
  height: 38px;
  background-image: url(/97d7c/img/windows_store_badge.png);
  background-size: 146px 38px;
}
.btn_android,
.btn_ios,
.btn_ios_large,
.btn_windows {
  border-radius: 0;
  background-color: transparent;
  border-bottom: 0;
}
.btn_android:focus,
.btn_android:hover,
.btn_ios:focus,
.btn_ios:hover,
.btn_ios_large:focus,
.btn_ios_large:hover,
.btn_windows:focus,
.btn_windows:hover {
  background-color: transparent;
}
.btn_android:after,
.btn_ios:after,
.btn_ios_large:after,
.btn_windows:after {
  display: none;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .btn_ios,
  .btn_ios:focus,
  .btn_ios:hover {
    background-image: url(/66f9/img/ios_app_store_badge@2x.png);
  }
  .btn_ios_large,
  .btn_ios_large:focus,
  .btn_ios_large:hover {
    background-image: url(/d1609/img/ios_app_store_badge_large@2x.png);
  }
  .btn_android,
  .btn_android:focus,
  .btn_android:hover {
    background-image: url(/66f9/img/google_play_badge@2x.png);
  }
  .btn_windows,
  .btn_windows:focus,
  .btn_windows:hover {
    background-image: url(/97d7c/img/windows_store_badge@2x.png);
  }
}

.btngrey1 {
  background: #f2f2f2;
  color: #ffffff;
}
.btngrey1.hover,
.btngrey1:focus,
.btngrey1:hover {
  background: #dddddd;
  color: #ffffff;
}

.btncolorprim {
  /*//	background:#439fe0;*/
  background-image: linear-gradient(45deg, #e73c33, #b70900);
  color: #ffffff;
}
.btncolorprim.hover:enabled,
.btncolorprim:focus:enabled,
.btncolorprim:hover:enabled {
  /*	background:#7bbce9;*/
  background: #e73c33;
  color: #ffffff;
}

.btncolorprimlight {
  background: #7bbce9;
  color: #ffffff;
}
.btncolorprimlight.hover,
.btncolorprimlight:focus,
.btncolorprimlight:hover {
  background: #7bbce9;
  color: #ffffff;
}
.btncolorsec {
  background: #fec799;
  color: #ffffff;
}
.btncolorsec.hover,
.btncolorsec:focus,
.btncolorsec:hover {
  background: #fec799;
  color: #ffffff;
}
.btncolortert {
  background: #fda094;
  color: #ffffff;
}
.btncolortert.hover,
.btncolortert:focus,
.btncolortert:hover {
  background: #fda094;
  color: #ffffff;
}
.btncolorquart {
  background: #fec799;
  color: #ffffff;
}
.btncolorquart.hover,
.btncolorquart:focus,
.btncolorquart:hover {
  background: #fec799;
  color: #ffffff;
}
.btnwhite {
  /*    background:#f2f2f2;*/
  background-image: linear-gradient(45deg, #ffffff, #eeeeee);
  color: #e73c33;
}
.btnwhite.hover,
.btnwhite:focus,
.btnwhite:hover {
  background: #eeeeee;
  color: #e73c33;
}

.btn_border.btncolorprim {
  border-color: #439fe0;
  color: #439fe0;
}
.btn_border.btncolorprimlight {
  border-color: #7bbce9;
  color: #7bbce9;
}
.btn_border.btncolorsec {
  border-color: #fec799;
  color: #fec799;
}
.btn_border.btncolortert {
  border-color: #fda094;
  color: #fda094;
}
.btn_border.btncolorquart {
  border-color: #fec799;
  color: #fec799;
}

.btn_outline.btn_transparent.btncolorprim {
  color: #439fe0 !important;
  border: 1px solid #439fe0 !important;
}
.btn_outline.btn_transparent.btncolorprim.active,
.btn_outline.btn_transparent.btncolorprim.hover,
.btn_outline.btn_transparent.btncolorprim:active,
.btn_outline.btn_transparent.btncolorprim:focus,
.btn_outline.btn_transparent.btncolorprim:hover {
  color: #439fe0 !important;
}
.btn_outline.btn_transparent.btncolorprimlight {
  color: #7bbce9 !important;
  border: 1px solid #7bbce9 !important;
}
.btn_outline.btn_transparent.btncolorprimlight.active,
.btn_outline.btn_transparent.btncolorprimlight.hover,
.btn_outline.btn_transparent.btncolorprimlight:active,
.btn_outline.btn_transparent.btncolorprimlight:focus,
.btn_outline.btn_transparent.btncolorprimlight:hover {
  color: #7bbce9 !important;
}
.btn_outline.btn_transparent.btncolorsec {
  color: #fec799 !important;
  border: 1px solid #fec799 !important;
}
.btn_outline.btn_transparent.btncolorsec.active,
.btn_outline.btn_transparent.btncolorsec.hover,
.btn_outline.btn_transparent.btncolorsec:active,
.btn_outline.btn_transparent.btncolorsec:focus,
.btn_outline.btn_transparent.btncolorsec:hover {
  color: #fec799 !important;
}
.btn_outline.btn_transparent.btncolortert {
  color: #fda094 !important;
  border: 1px solid #fda094 !important;
}
.btn_outline.btn_transparent.btncolortert.active,
.btn_outline.btn_transparent.btncolortert.hover,
.btn_outline.btn_transparent.btncolortert:active,
.btn_outline.btn_transparent.btncolortert:focus,
.btn_outline.btn_transparent.btncolortert:hover {
  color: #fda094 !important;
}
.btn_outline.btn_transparent.btncolorquart {
  color: #fec799 !important;
  border: 1px solid #fec799 !important;
}
.btn_outline.btn_transparent.btncolorquart.active,
.btn_outline.btn_transparent.btncolorquart.hover,
.btn_outline.btn_transparent.btncolorquart:active,
.btn_outline.btn_transparent.btncolorquart:focus,
.btn_outline.btn_transparent.btncolorquart:hover {
  color: #fec799 !important;
}
.btn_outline.btn_transparent.btnblack56 {
  color: #565656 !important;
  border: 1px solid #565656 !important;
  background: none;
}
.btn_outline.btn_transparent.btnblack56.active,
.btn_outline.btn_transparent.btnblack56.hover,
.btn_outline.btn_transparent.btnblack56:active,
.btn_outline.btn_transparent.btnblack56:focus,
.btn_outline.btn_transparent.btnblack56:hover {
  color: #439fe0 !important;
  background: #565656 !important;
}
.btn_outline.btn_transparent.btnblack4 {
  color: #444444 !important;
  border: 1px solid #444444 !important;
  background: none;
}
.btn_outline.btn_transparent.btnblack4.active,
.btn_outline.btn_transparent.btnblack4.hover,
.btn_outline.btn_transparent.btnblack4:active,
.btn_outline.btn_transparent.btnblack4:focus,
.btn_outline.btn_transparent.btnblack4:hover {
  color: #439fe0 !important;
  background: #444444 !important;
}
.btn_outline.btn_transparent.btnwhite {
  color: #ffffff !important;
  border: 1px solid #ffffff !important;
}
.btn_outline.btn_transparent.btnwhite.active,
.btn_outline.btn_transparent.btnwhite.hover,
.btn_outline.btn_transparent.btnwhite:active,
.btn_outline.btn_transparent.btnwhite:focus,
.btn_outline.btn_transparent.btnwhite:hover {
  color: #439fe0 !important;
}

.btnback {
  background: url('https://wwccdn.s3.amazonaws.com/images/icons/back.png')
    no-repeat 10px 6px transparent !important;
  color: #2980b9;
  border-color: #e8e8e8;
  -webkit-transition: background 0.6s, color 0.6s, border-color 0.6s;
  transition: background 0.6s, color 0.6s, border-color 0.6s;
}
.btnback:hover {
  background: url('https://wwccdn.s3.amazonaws.com/images/icons/back.png')
    no-repeat 10px 6px #e8e8e8 !important;
  border-color: #e8e8e8;
  -webkit-transition: background 0.6s, color 0.6s, border-color 0.6s;
  transition: background 0.6s, color 0.6s, border-color 0.6s;
}
.btnback:active {
  background: url('https://wwccdn.s3.amazonaws.com/images/icons/back.png')
    no-repeat 10px 6px #dddddd !important;
  border-color: #dddddd;
  -webkit-transition: background 0.6s, color 0.6s, border-color 0.6s;
  transition: background 0.6s, color 0.6s, border-color 0.6s;
}

.btnforward {
  background: url('https://wwccdn.s3.amazonaws.com/images/icons/forward.png')
    no-repeat 10px 6px transparent !important;
  color: #2980b9;
  border-color: #e8e8e8;
  -webkit-transition: background 0.6s, color 0.6s, border-color 0.6s;
  transition: background 0.6s, color 0.6s, border-color 0.6s;
}
.btnforward:hover {
  background: url('https://wwccdn.s3.amazonaws.com/images/icons/forward.png')
    no-repeat 10px 6px #e8e8e8 !important;
  border-color: #e8e8e8;
  -webkit-transition: background 0.6s, color 0.6s, border-color 0.6s;
  transition: background 0.6s, color 0.6s, border-color 0.6s;
}
.btnforward:active {
  background: url('https://wwccdn.s3.amazonaws.com/images/icons/forward.png')
    no-repeat 10px 6px #dddddd !important;
  border-color: #dddddd;
  -webkit-transition: background 0.6s, color 0.6s, border-color 0.6s;
  transition: background 0.6s, color 0.6s, border-color 0.6s;
}

.btnsearch {
  background: url('https://wwccdn.s3.amazonaws.com/images/icons/search.png')
    no-repeat 10px 6px transparent;
  color: #2980b9;
  border-color: #e8e8e8;
  -webkit-transition: background 0.6s, color 0.6s, border-color 0.6s;
  transition: background 0.6s, color 0.6s, border-color 0.6s;
}
.btnsearch:hover {
  background: url('https://wwccdn.s3.amazonaws.com/images/icons/search.png')
    no-repeat 10px 6px #e8e8e8;
  border-color: #e8e8e8;
  -webkit-transition: background 0.6s, color 0.6s, border-color 0.6s;
  transition: background 0.6s, color 0.6s, border-color 0.6s;
}
.btnsearch:active {
  background: url('https://wwccdn.s3.amazonaws.com/images/icons/search.png')
    no-repeat 10px 6px #dddddd;
  border-color: #dddddd;
  -webkit-transition: background 0.6s, color 0.6s, border-color 0.6s;
  transition: background 0.6s, color 0.6s, border-color 0.6s;
}

.btncross {
  background: url('https://wwccdn.s3.amazonaws.com/images/icons/cross.png')
    no-repeat 10px 6px transparent;
  color: #2980b9;
  border-color: #e8e8e8;
  -webkit-transition: background 0.6s, color 0.6s, border-color 0.6s;
  transition: background 0.6s, color 0.6s, border-color 0.6s;
}
.btncross:hover {
  background: url('https://wwccdn.s3.amazonaws.com/images/icons/cross.png')
    no-repeat 10px 6px #e8e8e8;
  border-color: #e8e8e8;
  -webkit-transition: background 0.6s, color 0.6s, border-color 0.6s;
  transition: background 0.6s, color 0.6s, border-color 0.6s;
}
.btncross:active {
  background: url('https://wwccdn.s3.amazonaws.com/images/icons/cross.png')
    no-repeat 10px 6px #dddddd;
  border-color: #dddddd;
  -webkit-transition: background 0.6s, color 0.6s, border-color 0.6s;
  transition: background 0.6s, color 0.6s, border-color 0.6s;
}

.btnbackred {
  background: url('https://wwccdn.s3.amazonaws.com/images/icons/back_red.png')
    no-repeat 10px 6px transparent;
  color: #e74c3c;
  border-color: #e8e8e8;
  -webkit-transition: background 0.6s, color 0.6s, border-color 0.6s;
  transition: background 0.6s, color 0.6s, border-color 0.6s;
}
.btnbackred:hover {
  background: url('https://wwccdn.s3.amazonaws.com/images/icons/back_red.png')
    no-repeat 10px 6px #e8e8e8;
  border-color: #e8e8e8;
  -webkit-transition: background 0.6s, color 0.6s, border-color 0.6s;
  transition: background 0.6s, color 0.6s, border-color 0.6s;
}
.btnbackred:active {
  background: url('https://wwccdn.s3.amazonaws.com/images/icons/back_red.png')
    no-repeat 10px 6px #dddddd;
  border-color: #dddddd;
  -webkit-transition: background 0.6s, color 0.6s, border-color 0.6s;
  transition: background 0.6s, color 0.6s, border-color 0.6s;
}

.btnforwardred {
  background: url('https://wwccdn.s3.amazonaws.com/images/icons/forward_red.png')
    no-repeat 10px 6px transparent;
  color: #e74c3c;
  border-color: #e8e8e8;
  -webkit-transition: background 0.6s, color 0.6s, border-color 0.6s;
  transition: background 0.6s, color 0.6s, border-color 0.6s;
}
.btnforwardred:hover {
  background: url('https://wwccdn.s3.amazonaws.com/images/icons/forward_red.png')
    no-repeat 10px 6px #e8e8e8;
  border-color: #e8e8e8;
  -webkit-transition: background 0.6s, color 0.6s, border-color 0.6s;
  transition: background 0.6s, color 0.6s, border-color 0.6s;
}
.btnforwardred:active {
  background: url('https://wwccdn.s3.amazonaws.com/images/icons/forward_red.png')
    no-repeat 10px 6px #dddddd;
  border-color: #dddddd;
  -webkit-transition: background 0.6s, color 0.6s, border-color 0.6s;
  transition: background 0.6s, color 0.6s, border-color 0.6s;
}

.btnsearchred {
  background: url('https://wwccdn.s3.amazonaws.com/images/icons/search_red.png')
    no-repeat 10px 6px transparent;
  color: #e74c3c;
  border-color: #e8e8e8;
  -webkit-transition: background 0.6s, color 0.6s, border-color 0.6s;
  transition: background 0.6s, color 0.6s, border-color 0.6s;
}
.btnsearchred:hover {
  background: url('https://wwccdn.s3.amazonaws.com/images/icons/search_red.png')
    no-repeat 10px 6px #e8e8e8;
  border-color: #e8e8e8;
  -webkit-transition: background 0.6s, color 0.6s, border-color 0.6s;
  transition: background 0.6s, color 0.6s, border-color 0.6s;
}
.btnsearchred:active {
  background: url('https://wwccdn.s3.amazonaws.com/images/icons/search_red.png')
    no-repeat 10px 6px #dddddd;
  border-color: #dddddd;
  -webkit-transition: background 0.6s, color 0.6s, border-color 0.6s;
  transition: background 0.6s, color 0.6s, border-color 0.6s;
}

.btncrossred {
  background: url('https://wwccdn.s3.amazonaws.com/images/icons/cross_red.png')
    no-repeat 10px 6px transparent;
  color: #e74c3c;
  border-color: #e8e8e8;
  -webkit-transition: background 0.6s, color 0.6s, border-color 0.6s;
  transition: background 0.6s, color 0.6s, border-color 0.6s;
}
.btncrossred:hover {
  background: url('https://wwccdn.s3.amazonaws.com/images/icons/cross_red.png')
    no-repeat 10px 6px #e8e8e8;
  border-color: #e8e8e8;
  -webkit-transition: background 0.6s, color 0.6s, border-color 0.6s;
  transition: background 0.6s, color 0.6s, border-color 0.6s;
}
.btncrossred:active {
  background: url('https://wwccdn.s3.amazonaws.com/images/icons/cross_red.png')
    no-repeat 10px 6px #dddddd;
  border-color: #dddddd;
  -webkit-transition: background 0.6s, color 0.6s, border-color 0.6s;
  transition: background 0.6s, color 0.6s, border-color 0.6s;
}

.sharebtnfacebook {
  background: url('https://wwccdn.s3.amazonaws.com/social/facebook-16.png')
    no-repeat 5px 8px #3b5998;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
  color: #f9f9f9;
  border-bottom-color: #223458;
}
.sharebtnfacebook:hover {
  background: url('https://wwccdn.s3.amazonaws.com/social/facebook-16.png')
    no-repeat 5px 8px #4060a5;
}
.sharebtnfacebook:active {
  background: url('https://wwccdn.s3.amazonaws.com/social/facebook-16.png')
    no-repeat 5px 8px #4060a5;
}
.sharebtntwitter {
  background: url('https://wwccdn.s3.amazonaws.com/social/twitter-16.png')
    no-repeat 5px 8px #39a9e0;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
  color: #f9f9f9;
  border-bottom-color: #2979a0;
}
.sharebtntwitter:hover {
  background: url('https://wwccdn.s3.amazonaws.com/social/twitter-16.png')
    no-repeat 5px 8px #3cb3ed;
}
.sharebtntwitter:active {
  background: url('https://wwccdn.s3.amazonaws.com/social/twitter-16.png')
    no-repeat 5px 8px #3cb3ed;
}

.sharebtn1facebook {
  background: url('https://wwccdn.s3.amazonaws.com/social/facebook-16.png')
    no-repeat 5px 8px #3b5998;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
  color: #f9f9f9;
}
.sharebtn1facebook:hover {
  background: url('https://wwccdn.s3.amazonaws.com/social/facebook-16.png')
    no-repeat 5px 8px #314a7e;
}
.sharebtn1facebook:active {
  background: url('https://wwccdn.s3.amazonaws.com/social/facebook-16.png')
    no-repeat 5px 8px #223458;
}
.sharebtn1twitter {
  background: url('https://wwccdn.s3.amazonaws.com/social/twitter-16.png')
    no-repeat 5px 8px #39a9e0;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
  color: #f9f9f9;
}
.sharebtn1twitter:hover {
  background: url('https://wwccdn.s3.amazonaws.com/social/twitter-16.png')
    no-repeat 5px 8px #3396c6;
}
.sharebtn1twitter:active {
  background: url('https://wwccdn.s3.amazonaws.com/social/twitter-16.png')
    no-repeat 5px 8px #194960;
}

.btngoogleplay {
  background: url('https://wwccdn.s3.amazonaws.com/social/googleplay-22.png')
    no-repeat 10px 8px #3498db;
  color: #f9f9f9;
  border-bottom-color: #256c9b;
  -webkit-transition: background 0.6s, color 0.6s;
  transition: background 0.6s, color 0.6s;
}
.btngoogleplay:hover {
  background: url('https://wwccdn.s3.amazonaws.com/social/googleplay-22.png')
    no-repeat 10px 8px #37a1e8;
  -webkit-transition: background 0.6s, color 0.6s;
  transition: background 0.6s, color 0.6s;
}
.btngoogleplay:active {
  background: url('https://wwccdn.s3.amazonaws.com/social/googleplay-22.png')
    no-repeat 10px 8px #37a1e8;
  -webkit-transition: background 0.6s, color 0.6s;
  transition: background 0.6s, color 0.6s;
}

.btngoogleplaysmall {
  background: url('https://wwccdn.s3.amazonaws.com/social/googleplay-13.png')
    no-repeat 10px 8px #3498db;
  color: #f9f9f9;
  border-bottom-color: #256c9b;
  -webkit-transition: background 0.6s, color 0.6s;
  transition: background 0.6s, color 0.6s;
}
.btngoogleplaysmall:hover {
  background: url('https://wwccdn.s3.amazonaws.com/social/googleplay-13.png')
    no-repeat 10px 8px #37a1e8;
  -webkit-transition: background 0.6s, color 0.6s;
  transition: background 0.6s, color 0.6s;
}
.btngoogleplaysmall:active {
  background: url('https://wwccdn.s3.amazonaws.com/social/googleplay-13.png')
    no-repeat 10px 8px #37a1e8;
  -webkit-transition: background 0.6s, color 0.6s;
  transition: background 0.6s, color 0.6s;
}

.underlinelinks {
  text-decoration: none;
  -webkit-transition: text-decoration 0.6s;
  transition: text-decoration 0.6s;
}
.underlinelinks:hover {
  text-decoration: underline;
  -webkit-transition: text-decoration 0.6s;
  transition: text-decoration 0.6s;
}

#fullscreenlight {
  display: none;
  position: fixed;
  left: 2%;
  top: 2%;
  z-index: 920;
  clear: both;
  overflow: hidden;
  width: 96%;
  height: 96%;
  background: rgba(210, 210, 210, 0.4);
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  moz-box-shadow: 0px 1px 2px #aaaaaa;
  -webkit-box-shadow: 0px 1px 2px #aaaaaa;
  box-shadow: 0px 1px 2px #aaaaaa;
  text-align: left;
}
#fullscreenlighttopbarbg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -50;
  background: url('https://wwccdn.s3.amazonaws.com/images/classy_fabric_white1.jpg')
    repeat scroll 0 0 transparent;
}
#fullscreenlighttopbar {
  position: relative;
  clear: both;
  position: relative;
  width: 100%;
  height: 10%;
}
#fullscreenlighttab1 {
  float: left;
  height: 100%;
  width: auto;
  padding: 0px 5px;
  color: #777777;
  font-size: 17px;
  font-family: 'Cabin-SemiBold', sans-serif;
  text-shadow: 0px 1px 0px #d2d2d2;
  -webkit-font-smoothing: antialiased;
  font-smoothing: always;
  overflow: hidden;
}
#fullscreenlighttabclose {
  float: right;
  height: 100%;
  width: auto;
  padding: 0px 5px;
  overflow: hidden;
  cursor: pointer;
}
#fullscreenlighttabclose:hover {
  background: rgba(200, 200, 200, 0.3);
}
.fullscreenlighttopbartabstables {
  height: 100%;
  width: 100%;
  text-align: center;
}
#fullscreenlightcontent {
  clear: both;
  position: relative;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 90%;
}
#fullscreenlightcontentbg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -50;
  background: url('https://wwccdn.s3.amazonaws.com/images/classy_fabric_white1.jpg')
    repeat scroll 0 0 transparent;
}
#fullscreenlightcontentmain {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

#fullscreendark {
  display: none;
  position: fixed;
  left: 1%;
  top: 1%;
  z-index: 921;
  clear: both;
  overflow: hidden;
  width: 98%;
  height: 98%;
  background: #111111;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  moz-box-shadow: 0px 0px 3px #000000;
  -webkit-box-shadow: 0px 0px 3px #000000;
  box-shadow: 0px 0px 3px #000000;
  text-align: left;
}
#fullscreendarktopbarbg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -50;
  background: url('https://wwccdn.s3.amazonaws.com/images/classy_fabric.jpg')
    repeat scroll 0 0 transparent;
}
#fullscreendarktopbar {
  position: relative;
  clear: both;
  position: relative;
  width: 100%;
  height: 6%;
  background: #111111;
}
#fullscreendarktab1 {
  float: left;
  height: 100%;
  width: auto;
  padding: 0px 5px;
  color: #999999;
  font-size: 17px;
  font-family: 'Cabin-SemiBold', sans-serif;
  text-shadow: 0px 1px 0px #000000;
  -webkit-font-smoothing: antialiased;
  font-smoothing: always;
  overflow: hidden;
}
#fullscreendarktabclose {
  float: right;
  height: 100%;
  width: auto;
  padding: 0px 5px;
  overflow: hidden;
  cursor: pointer;
}
#fullscreendarktabclose:hover {
  background: rgba(0, 0, 0, 0.4);
}
.fullscreendarktopbartabstables {
  height: 100%;
  width: 100%;
  text-align: center;
}
.stdtable {
  height: 100%;
  width: 100%;
  text-align: center;
}
#fullscreendarkcontent {
  clear: both;
  position: relative;
  width: 100%;
  height: 100%;
}
#fullscreendarkcontentbg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -50;
  background: url('https://wwccdn.s3.amazonaws.com/images/classy_fabric.jpg')
    repeat scroll 0 0 transparent;
}
#fullscreendarkcontentmain {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

#fullscreenpopup {
  display: none;
  position: fixed;
  top: 60px;
  left: 0px;
  z-index: 801;
  clear: both;
  overflow: hidden;
  width: 100%;
  height: 100%;
  text-align: center;
}
#fullscreenpopupcontent {
  position: relative;
  clear: both;
  width: 100%;
  height: 100%;
}
#fullscreenpopupcontentmain {
  position: absolute;
  clear: both;
  color: white;
  width: auto;
  height: auto;
  max-width: 95%;
  max-height: 95%;
  background: #444444;
  text-align: center;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  moz-box-shadow: 0px 0px 3px #000000;
  -webkit-box-shadow: 0px 0px 3px #000000;
  box-shadow: 0px 0px 3px #000000;
  overflow: auto;
}

.thumb {
  height: 75px;
  border: 1px solid #000;
  margin: 10px 5px 0 0;
}

.toast {
  position: fixed;
  z-index: 990;
  display: hidden;
  width: auto;
  height: auto;
  padding: 15px 20px;
  font-size: 12px;
  font-family: Josefin Sans, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-smoothing: always;
  overflow: hidden;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  /*	box-shadow: 0px 0px 5px rgba(0, 0, 0, .3);  */
}
.toastblue {
  background: #6fc1d2;
  color: #fcfcfc;
}
.toastblue1 {
  background: #428e9e;
  color: #fcfcfc;
}
.toastpink {
  background: #dd6bae;
  color: #fcfcfc;
}
.toastpink1 {
  background: #a0497c;
  color: #fcfcfc;
}
.toastyellow {
  background: #dfbc42;
  color: #fcfcfc;
}
.toastyellow1 {
  background: #9d8222;
  color: #fcfcfc;
}
.toastgreen {
  background: #7dc692;
  color: #fcfcfc;
}
.toastgreen1 {
  background: #508b61;
  color: #fcfcfc;
}
.toastred {
  background: #e65d5d;
  color: #fcfcfc;
}
.toastred1 {
  background: #c43434;
  color: #fcfcfc;
}
.toastbrown {
  background: #d98e64;
  color: #fcfcfc;
}
.toastbrown1 {
  background: #a06849;
  color: #fcfcfc;
}

.stdtable {
  height: 100%;
  width: 100%;
  text-align: center;
}

#screensaver {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: none;
}
#screensaver1 {
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 65%;
  background: rgba(41, 128, 185, 0.9);
}
#screensaver1textouter {
  position: absolute;
  bottom: 20px;
  left: 0px;
  width: 100%;
  height: auto;
  text-align: center;
}
#screensaver1text1 {
  width: 900px;
  margin: auto;
  height: auto;
  color: #f9f9f9;
  /*	font-family:Josefin Sans, sans-serif;
	font-weight: 500;*/
  font-family: Josefin Sans, sans-serif;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
}
#screensaver1text2 {
  width: 900px;
  margin: auto;
  height: auto;
  color: #ffffff;
  /*	font-family:Josefin Sans, sans-serif;
	font-weight: 700;*/
  font-family: Josefin Sans, sans-serif;
  font-weight: 900;
  font-size: 70px;
  text-transform: uppercase;
}
#screensaver2 {
  position: relative;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 35%;
  background: #f2f2f2;
}
#screensaver2textouter {
  position: absolute;
  top: 80px;
  left: 0px;
  width: 100%;
  height: auto;
  text-align: center;
}
#screensaver2text1 {
  width: 900px;
  margin: auto;
  height: auto;
  color: #f9f9f9;
  font-family: Josefin Sans, sans-serif;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
}

.t-offset {
  transform: translateY(20px);
}
.t-delay-0 {
  animation-delay: 0.4s;
}
.t-delay-1 {
  animation-delay: 0.4s;
}
.t-delay-2 {
  animation-delay: 0.51s;
}
.t-delay-3 {
  animation-delay: 0.7s;
} /*
.t-delay-4{
	transition-delay:1s
}
.t-delay-5{
	transition-delay:1.2s
}
.t-delay-6{
	transition-delay:1.4s
}
.t-delay-7{
	transition-delay:1.6s
}*/
/*
.t-fadein{
	transition-duration:3s;
	transition-property:opacity,transform;
	opacity:1;
	transform:translate3d(0,0,0);
	transform:translateY(0)
}*/

.t-fadein-offset {
  animation-duration: 1s;
  animation-name: fadein-offset;
  /*  animation-delay: 5s;*/
  -webkit-animation-duration: 1s;
  -webkit-animation-name: fadein-offset;
  /*  -webkit-animation-delay: 5s;*/
}

@-moz-keyframes fadein-offset {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -webkit-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}
@-webkit-keyframes fadein-offset {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -webkit-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}
@-o-keyframes fadein-offset {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -webkit-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}
@keyframes fadein-offset {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -webkit-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}

.t-fadein-offset-1 {
  animation-duration: 1s;
  animation-name: fadein-offset-1;
  /*  animation-delay: 5s;*/
  -webkit-animation-duration: 1s;
  -webkit-animation-name: fadein-offset-1;
  /*  -webkit-animation-delay: 5s;*/
}

@-moz-keyframes fadein-offset-1 {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}
@-webkit-keyframes fadein-offset-1 {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}
@-o-keyframes fadein-offset-1 {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}
@keyframes fadein-offset-1 {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}

/*.scroll-anim-1-1, .scroll-anim-1-2, .scroll-anim-1-3, .scroll-anim-1-4{
	opacity: 0;
}*/

@-moz-keyframes fadein-1 {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadein-1 {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadein-1 {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadein-1 {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.champ-box {
  -webkit-animation: fadein-1 1s ease-out;
  -moz-animation: fadein-1 1s ease-out;
  -o-animation: fadein-1 1s ease-out;
  animation: fadein-1 1s ease-out;
}

@media only screen and (max-width: 768px) {
  body {
    font-size: 16px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0px;
  }
  h1 {
    font-size: 1.75rem;
    line-height: 2rem;
  }
  h2 {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
  h3 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  h4 {
    font-size: 1.1rem;
    line-height: 1.25rem;
  }
  h1.large {
    font-size: 2.25rem;
    line-height: 2.5rem;
    letter-spacing: -1px;
  }

  ol,
  ul {
    padding-left: 25px;
  }
  li {
    margin-left: 15px;
  }

  .hide_on_mobile {
    display: none !important;
  }
  .show_on_mobile {
    display: block !important;
  }
  .show_on_mobile.inline {
    display: inline !important;
  }
  .show_on_mobile.inline_block {
    display: inline-block !important;
  }
  .align_center_mobile {
    text-align: center;
  }
}

@media only screen and (max-width: 320px) {
  body {
    font-size: 14px;
  }

  ol,
  ul {
    padding-left: 15px;
  }
  li {
    margin-left: 5px;
  }
}


.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #5cb85c;;
}

input:focus + .slider {
  box-shadow: 0 0 1px #5cb85c;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}


input:checked + .slider:after {
	content: 'Yes';
	color: #fff;
	font-size: 14px;
	position: absolute;
	top: 50%;
	left: 8%;
	transform: translate(0, -50%);
}

input + .slider:after {
    content: 'No';
    color: #fff;
    font-size: 14px;
    position: absolute;
    top: 50%;
		right: 8%;
		transform: translate(0, -50%);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.heading-input-div{
  display: flex;
  justify-content: center;
  font-size:15px;
}

.heading-input-div > .heading-input{
  font-size:15px;
  margin-left:10px;
  height:40px;
  width: 35vw;
}

.heading-name{
  margin-top: 16px;
  padding-left: 16px;
  width: 14vw;
  font-size:15px;
}
