.loaderBtn {
  width: 4.5rem;
  height: 2rem;
  border-radius: 5px;
  outline: none;
  border: 1px solid #e73c33;
  background-image: linear-gradient(
    45deg, #e73c33, #b70900);
  box-shadow: 0px 5px 10px rgb(50 50 50 / 30%);
}
.whiteloaderBtn {
  width: 4.5rem;
  height: 2rem;
  border-radius: 5px;
  outline: none;
  border: 1px solid #fff;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgb(50 50 50 / 30%);
}
[data-selected="true"] {
  background-image: linear-gradient(
    45deg, #e73c33, #b70900) !important;
}

.flex {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  
  .mainDots {
    border-radius: 50%;
    animation: dotCarousel .5s infinite linear;
  }
  
  .mainDots2 {
    border-radius: 50%;
    animation: dotCarousel 1.25s infinite linear;
  }
  
  .mainDots3 {
    border-radius: 50%;
    animation: dotCarousel 0.75s infinite linear;
  }
  
  @keyframes dotCarousel {
    0% {
      background-color: #fff;
      opacity: 0.2;
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
    50% {
      background-color: #fff;
      opacity: 1;
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }
    100% {
      background-color: #fff;
      opacity: 0.5;
      width: 5px;
      height: 5px;
      border-radius: 50%;
    }
  }