.selectionDiv {
    min-width: 83px;
    height: 32px;
    border-radius: 19px;
    border: 1px solid rgba(40,40,40,.2);
    background-color: #fff;
    margin-bottom: 1rem;
    text-align: center;
    margin-right: 1rem;
    padding: 0 8px;
    cursor: pointer;
    margin-top: 1rem;
}

.selectedSpan {
    line-height: 2.36;
    letter-spacing: .13px;
    text-align: left;
    color: #282828;
    font-size: 14px;
    font-weight: 400;
}

.crossImage {
    width: 8px;
    height: 8px;
    margin-left: 8px;
    vertical-align: middle;
}

.subCities {
    margin: 10px;
    display: flex;
}

.clusterStyle {
    display: flex;
    align-items: center;

    /* text-align: center; */
    /* vertical-align: middle; */
    justify-content: center;
    /* width: 16%; */
    line-height: 21px;
    border-color: red;
    color: red;
}