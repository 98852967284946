.epc-image-section {
	position: absolute;
	width: 100%;
	height: auto;
	top: 270px;
	margin-left: 5px;
	display: flex;
	gap: 0.5rem;
	justify-content: space-between;
	width: 100%;
}

.epc-image-section > div {
	display: flex;
	flex: 1;
}
::-webkit-input-placeholder { /* WebKit browsers */
    text-transform: none;
}
.sticky {
	position: sticky;
	position: -webkit-sticky;
	/* width: 100px; */
	height: 100%;
	top: 0px;
	overflow: auto;
	color: #fff;
}
#customer-discount{
	height: 4rem;
}
#customer-discount:hover{
background: #fafafa;
    color: #444444;
    font-family: 'hurme1semibold';
    box-shadow: 0px 2px 5px rgb(50 50 50 / 30%);
    -webkit-box-shadow: 0px 2px 5px rgb(50 50 50 / 30%);
}

.zoom_part {
	position: absolute;
	width: 100%;
	top: 245px;
	margin-left: 15px;
	z-index: 2;
	border-radius: 2px;
}
.zoom-In {
	width: 3rem;
	margin-right: 0.5rem;
	background-image: linear-gradient(45deg, #e73c33, #b70900);
	color: #ffffff;
	line-height: 1.2rem;
	font-family: 'hurme1bold', sans-serif;
	user-select: none;
	text-decoration: none;
	cursor: pointer;
	text-shadow: 0 1px 1px rgb(0 0 0 / 10%);
	border: none;
	border-radius: 0.25rem;
	position: relative;
	display: inline-block;
	vertical-align: bottom;
	text-align: center;
	white-space: nowrap;
	font-size: 1.1rem;
}

.zoom-Out {
	width: 3rem;
	margin-right: 0.5rem;
	background-image: linear-gradient(45deg, #e73c33, #b70900);
	color: #ffffff;
	line-height: 1.2rem;
	font-family: 'hurme1bold', sans-serif;
	user-select: none;
	text-decoration: none;
	cursor: pointer;
	text-shadow: 0 1px 1px rgb(0 0 0 / 10%);
	border: none;
	border-radius: 0.25rem;
	position: relative;
	display: inline-block;
	vertical-align: bottom;
	text-align: center;
	white-space: nowrap;
	font-size: 1.1rem;
}
.zoom-In:hover {
	transform: translateY(-2px);
	box-shadow: 0 10px 10px rgb(50 50 50 / 30%);
}
.zoom-Out:hover {
	transform: translateY(-2px);
	box-shadow: 0 10px 10px rgb(50 50 50 / 30%);
}

#myImg{
	padding: 5px;
	border: 1px solid transparent;
	object-fit: contain;
    height: auto;
    width: 150px;
}
#myImg:hover{
	cursor: pointer;
	border:1px solid #C5C5C5;
}
.modal {
	display: none; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	padding-top: 100px; /* Location of the box */
	left: 0;
	top: 60px;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0,0,0); /* Fallback color */
	background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
  }
  .modal-content {
	margin: auto;
	display: block;
	width: 80%;
	max-width: 610px;
  }
  .modal-content {  
	-webkit-animation-name: zoom;
	-webkit-animation-duration: 0.6s;
	animation-name: zoom;
	animation-duration: 0.6s;
  }
  @-webkit-keyframes zoom {
	from {-webkit-transform:scale(0)} 
	to {-webkit-transform:scale(1)}
  }
  
  @keyframes zoom {
	from {transform:scale(0)} 
	to {transform:scale(1)}
  }
  
  /* The Close Button */
  .close {
    position: absolute;
    top: 30px;
    right: 360px;
    color: #f1f1f1;
    font-size: 52px;
    font-weight: bold;
    transition: 0.3s;
}
  
  .close:hover,
  .close:focus {
	color: #bbb;
	text-decoration: none;
	cursor: pointer;
  }
  
  /* 100% Image Width on Smaller Screens */
  @media only screen and (max-width: 700px){
	.modal-content {
	  width: 100%;
	}
  }
#parentDiv {
	position: relative;
	padding: 1px;
	margin: 1px auto;
	width: 100%;
	/* height: 400px; */
	overflow: auto;
}
.discountForm{
	height: 18px;
	width: 18px;
}
#part-img {
	height: auto;
}

.childDiv {
	position: absolute;
	background-color: yellow;
	display: grid;
	color: black;
	font-size: 12px;
	font-family: 'hurme1regular', sans-serif;
	place-items: center;
	padding: 1px 5px;
	top: 50px;
	left: 50px;
	cursor: pointer;
	border: 1px solid black;
}
.category-drop{
    font-size: 16px;
    padding: 0.7rem 0.5rem 0rem 0.5rem;
    height: 25px;
    border-radius: 2px;
    border-bottom: solid 2px #777777;
    background-color: rgba(255, 255, 255, 0.36);
    position: relative;
    color: #e73c33;
    font-weight: 600;
    display: flex;
}
.category-drop:last-child {
    border-bottom: none;
}

.childDiv:hover,
.highlightedBadge {
	background-color: red;
	color: white;
	z-index: 5;
}

@media (max-width: 700px) {
	.childDiv {
		font-size: 0.5rem;
	}
	.epc-image-section {
		flex-direction: column;
	}
}
.centerAlign{
	text-align: center!important;
}
.bannerImg{
    object-fit: contain;
    height: 70px;
    width: 100%;
}
.bannerSize{
	margin-top: 4rem;
    padding-left: 1rem!important;
}
.badge-del {
	position: absolute;
	top: -8px;
	right: -10px;
	padding: 0.01rem 0.2rem;
	background-color: #00000066;
	border-radius: 50%;
	visibility: hidden;
	display: grid;
	place-items: center;
}
.yearRangeData{
	box-shadow: 0px 2px 5px rgb(50 50 50 / 12%);
    border: 1px solid #C5C5C5;
    border-radius: 0.25rem;
    width: 96% !important;
    margin: 2rem 0 1rem 1rem;
	padding-top:0.7rem !important;
}
.mariginZero{
	margin-bottom: 0.2rem !important
}

.childDiv:hover .badge-del {
	visibility: visible;
}

.badge-del:hover {
	padding: 0.05rem 0.3rem;
}
.heighty{
	height: 70px;
  }
.epc-table {
	height: fit-content;
	text-align: left;
}

.epc-table input[type='text'] {
	font-size: 1rem;
	min-width: 60px;
	max-width: fit-content;
	margin-right: 10px;
	padding: 0.6em 0.3em;
}

.epc-table thead {
	background-color: rgba(197, 197, 197, 0.603);
}

.epc-table tr {
	height: 2rem;
	vertical-align: inherit;
}

.epc-table input[type='checkbox'] {
	height: 18px;
	width: 18px;
}

.epc-table tr:hover,
.highlightedRow {
	cursor: pointer;
	background: rgba(197, 197, 197, 0.603);
}

.family-input {
	font-size: 16px !important;
	font-weight: bold;
	height: 2.3rem !important;
}

.epc-file-upload {
	height: 30px !important;
	font-size: 14px !important;
}

.upload-row {
	display: flex;
	height: 3.5rem !important;
	margin-bottom: 1rem;
}

.border-bottom {
	border-bottom: 1px solid #ddd;
}

.dropdown-check-list ul,
.dropdown-check-list {
	text-align: start;
}
.filter_key{
	padding-left: 1rem;
	padding-bottom: 1rem;
	font-size:17px;
	text-transform: uppercase;
}

.d-flex {
	display: flex;
	font-size: 14px;
}

.f-aligncenter {
	align-items: center;
}

.f-justifybetween {
	justify-content: space-between;
}

.f-justifyaround {
	justify-content: space-around;
}

.discount-input.discount-input {
	width: 12% !important;
}

.width-100 {
	width: 100% !important;
}
.mtop-0p5 {
	margin-top: .5rem;
}
.mtop-1 {
	margin-top: 1rem;
}

.mbottom-1 {
	margin-bottom: 1rem;
}

.f-gap-0p5 {
	gap: 0.5rem;
}

.f-gap-1 {
	gap: 1rem;
}

.f-dircol {
	flex-direction: column;
}

.height-100 {
	height: 100% !important;
}

.f-c4 {
	flex-wrap: wrap;
	gap: 0.5rem;
}
.f-c4 > * {
	width: 23% !important;
}

.margin-0 {
	margin: 0 !important;
}
.font-15{
	font-size: 15px !important;
	text-transform: uppercase;
}
.bulk-discount{
	font-size:15px;
	height: 1.5rem;
}

.discount-heading {
	padding: 0.5em 1em;
	background: linear-gradient(45deg, #ffffff, #eeeeee);
	border: 0.5px solid #e7e7e7;
	border-radius: 5px;
	font-size: 16px;
    width: 12%;
    margin: auto;
    text-transform: uppercase;
	text-align: center;
}
.homepage-heading {
	padding: 0.5em 1em;
	background: linear-gradient(45deg, #ffffff, #eeeeee);
	border: 0.5px solid #e7e7e7;
	border-radius: 5px;
	font-size: 16px;
    margin: auto;
    text-transform: uppercase;
	text-align: center;
}

.master-tab-container {
	height: 3rem !important;
	background: #e3e3e3;
	border-radius: 5px;
	padding: 0 !important;
	justify-content: space-around;
}
.master-tab-container > div {
	flex: 1;
}
.active-master-tab {
	background: #efefef;
}

.banner-tab-container {
	display: flex;
	height: 3rem !important;
	background: #e3e3e3;
	border-radius: 5px;
	padding: 0 !important;
	justify-content: space-around;
	align-items: center;
}
.banner-tab-container > div {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
}
.active-banner-tab {
	background: #efefef;
}

.gateway-tab-container {
	display: flex;
	height: 3rem !important;
	background: #e3e3e3;
	border-radius: 5px;
	padding: 0 !important;
	justify-content: space-around;
	align-items: center;
}
.gateway-tab-container > div {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
}
.active-gateway-tab {
	background: #efefef;
}

.private-lvl-row {
	height: 40px !important;
}

.private-lvl-row:not(:last-child) {
	width: 20% !important;
}

.private-lvl-row input {
	font-size: 1rem !important;
}

.pl-cashback-Container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
  gap: 2px;
  margin-bottom: 1rem;
  width: 100%;
}

.pl-cashback-tab {
  background: #eee;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
}

.tech-specs-container {
  display: grid;
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.tech-specs-table-container {
  margin-top: 2rem;
  display: grid;
  gap: 1rem;
}

.tech-specs-table-header {
  margin: 0;
  font-weight: 600;
  color: rgba(0,0,0,0.87);
}

.tech-specs-table {
  text-align: center;
  border: 1px solid #f6f6f6;
}

.tech-specs-table > tr {
  border: 1px solid #fff;
  background: #f6f6f6;
  display: grid;
  grid-template-columns: 1fr 1fr 3fr;
}

.tech-specs-table > tr > th {
  overflow-wrap: anywhere;
  border: 1px solid #fff;
  padding: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tech-specs-table > tr > td {
  overflow-wrap: anywhere;
  border: 1px solid #fff;
  padding: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}