.attr-container{
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	margin-top: .3rem;
	background: #d5d5d5bf;

}
.attr-tab-parent{
	position: relative;
	/* background-color: #ebebeb80 */
}
.attr-tab{
	vertical-align: bottom;
    text-align: center;
    white-space: nowrap;
	display: inline-block;
	background-image: linear-gradient(
		45deg, #ffffff, #eeeeee);
			color: #e73c33;
	border: none;
	font-size: 14px;
	line-height: 1.2rem;
    font-family: 'hurme1bold', sans-serif;
	cursor: pointer;
    text-shadow: 0 1px 1px rgb(0 0 0 / 10%);
    border-radius: 0.25rem;
	-webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    font-size: 15px;
    text-transform: uppercase;
    margin: 2px;
}
.attr-name{
	margin: auto;
	padding: .6rem .9rem .6rem .9rem;
}
/* .attr-tab:hover{
	transform: translateY(-2px);
    box-shadow: 0 10px 10px rgb(50 50 50 / 30%);
	background: #eeeeee;
    color: #e73c33;
} */

.attr-tab:hover{
	background-image: linear-gradient(
		45deg, #e73c33, #b70900);
			color: #ffffff;
	transform: translateY(-2px);
    box-shadow: 0 10px 10px rgb(50 50 50 / 30%);
}

.active-attr-tab{
	background-image: linear-gradient(
		45deg, #e73c33, #b70900);
			color: #ffffff;
	transform: translateY(-2px);
    box-shadow: 0 10px 10px rgb(50 50 50 / 30%);
}
.drop-down{
	position: absolute;
	display: none;
	background-image: linear-gradient( 
		45deg, #ffffff, #eeeeee);
	min-width: 7.5rem;
	left: 2px;
    z-index: 1;
	cursor: pointer;
	transform: translateY(-2px);
    box-shadow: 0 10px 10px rgb(50 50 50 / 30%);
	font-family: 'hurme1bold', sans-serif;
}

.subMenu-element{
	line-height: 0.6rem;
    font-size: .9rem;
	color: #e73c33;
	text-align: center;
    padding: 12px 16px;
    text-decoration: none;
    text-align: center;

}
.subMenu-element:hover{
	background-color: rgba(255, 236, 236, 0.87);
}
.attr-tab-parent:hover .drop-down{
	display: block;
}
.adjusting-div{
	text-align: start;
	margin-left: 1.5rem!important;
	margin-right: 1.5rem!important;
	margin-top: 4.5rem;
	color: #e73c33;
	font-family: 'hurme1bold', sans-serif;
}
.faq_styled{
	text-align: left;
	margin-top: 1rem;
	background-color: #f9f9f9;
	margin-bottom: 3rem;
	border-radius:2px;	
	border: 1px solid #ccc;
	box-shadow: 1px 1px 1px #999
}
.faq-label{
	margin-left: 2rem;
	font-size: 16px;
}
.faq-input{
	width: 92%!important;
	margin-left: 1.5rem!important;
	margin-right: 1.5rem!important;
	font-size: .83rem!important;
}
.product-container{
	margin-top: .3rem;
	display: flex;
	justify-content: space-between;
}
.faqs{
	/* margin-top: 1rem; */
}
.position_relative{
	position: relative;
}
.numbering-padding{
	position: absolute;
	top: 3.2px;
    left: 10px;
	font-size: .85rem;

}
.formm{
	padding-top: .4rem;
	margin-bottom: 2rem;
	font-size: .9rem;

}
.btn-div{
	display: flex;
	justify-content: end;
}
/* about gm spares */
textarea {
    padding: 10px;
    line-height: 1rem;
    border-radius: 5px;
	min-height:15rem;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px #999;
}
.content_tab{
	display: flex;
	justify-content: flex;
	align-items: center;
}
.epc-image-section {
	position: absolute;
	width: 100%;
	height: auto;
	top: 270px;
	margin-left: 5px;
	display: flex;
	gap: 0.5rem;
	justify-content: space-between;
	width: 100%;
}

.epc-image-section > div {
	display: flex;
	flex: 1;
}

.sticky {
	position: sticky;
	position: -webkit-sticky;
	/* width: 100px; */
	height: 100%;
	top: 0px;
	overflow: auto;
	color: #fff;
}

.zoom_part {
	position: absolute;
	width: 100%;
	top: 245px;
	margin-left: 15px;
	z-index: 2;
	border-radius: 2px;
}
.zoom-In{
	width: 3rem;
	margin-right: .5rem;
	background-image: linear-gradient(
		45deg, #e73c33, #b70900);
			color: #ffffff;
		    line-height: 1.2rem;
    font-family: 'hurme1bold', sans-serif;
	user-select: none;
    text-decoration: none;
    cursor: pointer;
    text-shadow: 0 1px 1px rgb(0 0 0 / 10%);
    border: none;
    border-radius: 0.25rem;
    position: relative;
    display: inline-block;
    vertical-align: bottom;
    text-align: center;
    white-space: nowrap;
	font-size: 1.1rem;
}

.zoom-Out{
	width: 3rem;
	margin-right: .5rem;
	background-image: linear-gradient(
		45deg, #e73c33, #b70900);
			color: #ffffff;
		    line-height: 1.2rem;
    font-family: 'hurme1bold', sans-serif;
	user-select: none;
    text-decoration: none;
    cursor: pointer;
    text-shadow: 0 1px 1px rgb(0 0 0 / 10%);
    border: none;
    border-radius: 0.25rem;
    position: relative;
    display: inline-block;
    vertical-align: bottom;
    text-align: center;
    white-space: nowrap;
	font-size: 1.1rem;
}
.zoom-In:hover{
	transform: translateY(-2px);
    box-shadow: 0 10px 10px rgb(50 50 50 / 30%);
}
.zoom-Out:hover{
	transform: translateY(-2px);
    box-shadow: 0 10px 10px rgb(50 50 50 / 30%);
}


#parentDiv {
	position: relative;
	padding: 1px;
	margin: 1px auto;
	width: 100%;
}

#part-img {
	height: auto;
}

.childDiv {
	position: absolute;
	background-color: yellow;
	display: grid;
	color: black;
	font-size: 12px;
	font-family: 'hurme1regular', sans-serif;
	place-items: center;
	padding: 1px 5px;
	top: 50px;
	left: 50px;
	cursor: pointer;
	border: 1px solid black;
}

.childDiv:hover,
.highlightedBadge {
	background-color: red;
	color: white;
	z-index: 5;
}

@media (max-width: 700px) {
	.childDiv {
		font-size: 0.5rem;
	}
	.epc-image-section {
		flex-direction: column;
	}
}

.badge-del {
	position: absolute;
	top: -8px;
	right: -10px;
	padding: 0.01rem 0.2rem;
	background-color: #00000066;
	border-radius: 50%;
	visibility: hidden;
	display: grid;
	place-items: center;
}

.childDiv:hover .badge-del {
	visibility: visible;
}

.badge-del:hover {
	padding: 0.05rem 0.3rem;
}

.epc-table {
	height: fit-content;
	text-align: left;
}

.epc-table input[type='text'] {
	font-size: 1rem;
	min-width: 60px;
	max-width: fit-content;
	margin-right: 10px;
	padding: 0.6em 0.3em;
}

.epc-table thead {
	background-color: rgba(197, 197, 197, 0.603);
}

.epc-table tr {
	height: 2rem;
	vertical-align: inherit;
}

.epc-table input[type='checkbox'] {
	height: 18px;
	width: 18px;
}

.epc-table tr:hover,
.highlightedRow {
	cursor: pointer;
	background: rgba(197, 197, 197, 0.603);
}

.family-input {
	font-size: 16px !important;
	font-weight: bold;
	height: 2.3rem !important;
}

.epc-file-upload {
	height: 30px !important;
	font-size: 14px !important;
}

.upload-row {
	display: flex;
	height: 3.5rem !important;
	margin-bottom: 1rem;
}

.border-bottom {
	border-bottom: 1px solid #ddd;
}

.dropdown-check-list ul,
.dropdown-check-list {
	text-align: start;
}

.d-flex {
	display: flex;
	font-size: 16px;
}

.f-aligncenter {
	align-items: center;
}

.f-justifybetween {
	justify-content: space-between;
}

.f-justifyaround {
	justify-content: space-around;
}

.discount-input.discount-input {
	width: 12% !important;
}

.width-100 {
	width: 100% !important;
}

.mtop-1 {
	margin-top: 1rem;
}

.mbottom-1 {
	margin-bottom: 1rem;
}

.f-gap-0p5 {
	gap: 0.5rem;
}

.f-gap-1 {
	gap: 1rem;
}

.f-dircol {
	flex-direction: column;
}

.height-100 {
	height: 100% !important;
}

.f-c4 {
	flex-wrap: wrap;
	gap: 0.5rem;
}
.f-c4 > * {
	width: 23% !important;
}

.margin-0 {
	margin: 0 !important;
}

.discount-heading {
	padding: 0.5em 1em;
	background: linear-gradient(45deg, #ffffff, #eeeeee);
	border: 0.5px solid #e7e7e7;
	border-radius: 5px;
}

.master-tab-container {
	height: 3rem !important;
	background: #e3e3e3;
	border-radius: 5px;
	padding: 0 !important;
	justify-content: space-around;
}
.master-tab-container > div {
	flex: 1;
}
.active-master-tab {
	background: #efefef;
}

.banner-tab-container {
	display: flex;
	height: 3rem !important;
	background: #e3e3e3;
	border-radius: 5px;
	padding: 0 !important;
	justify-content: space-around;
	align-items: center;
}
.banner-tab-container > div {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
}
.active-banner-tab {
	background: #efefef;
	
}
