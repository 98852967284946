.dropdown-check-list {
    height: 48px;
    border-radius: 5px;
    border: solid 1px #d0d0d0;
    background-color: rgba(255, 255, 255, 0.36);
    position: relative;
    display: flex;
}

.dropdown-check-list .anchor {
    padding-left: 5px;
    border: none;
    width: 100%;
    background: transparent;
    font-size: 16px;
    font-family: inherit;
    color: rgba(0, 0, 0, 0.87);
}
.anchor:focus {
    outline: none;
}
.anchor:focus + .options {
    display: block;
}
.dropdown-check-list .anchor:after {
    position: absolute;
    content: "";
    border-left: 2px solid black;
    border-top: 2px solid black;
    padding: 5px;
    right: 10px;
    top: 20%;
    -moz-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

.dropdown-check-list .anchor:active:after {
    right: 8px;
    top: 21%;
}


.dropdown-check-list .color-black-placeholder::placeholder {
    color: black;
}

.options-display-none {
    visibility: hidden !important;
}

.options:hover {
    display: block !important;
}

.dropdown-check-list ul.options {
    padding: 2px;
    display: none;
    margin: 0;
    border: 1px solid #ccc;
    border-top: none;
    position: absolute;
    top: 50px;
    background: white;
    z-index: 9;
    max-height: 350px;
    overflow: auto;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    font-size: smaller;
}
.dropdown-check-list ul.options li {
    list-style: none;
    width: 100%;
    display: inline-flex;
    padding: 0;
    border-bottom: 1px solid #e5e5e5;
}

.dropdown-check-list ul.options li > label {
    width: 100%;
    display: inline-block;
    padding: 8px 0px;
    cursor: pointer;
}
.margin_l_8 {
    margin-left: 8px;
}
/* .dropdown-check-list ul.options li {padding:8px 16px;border-bottom:1px solid #e5e5e5;cursor:pointer;} */
.dropdown-check-list ul.options li:hover{background-color:#e5e5e5;color:black;font-weight:600;}

.dropdown-check-list ul.options li > label > input:checked + span.margin_l_8 {
    color: green;
    font-weight: 600;
}

.dropdown-check-list ul.options li > label > input:disabled {
    background: red;
    font-weight: 600;
}


.dropdown-check-list .selected-label {
    height: 15px;
    position: absolute;
    white-space: nowrap;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1;
    letter-spacing: 0.00938em;
    top: 4px;
    left: 5px;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.54);
    overflow-x: auto;
    width: 95%;
}

.dropdown-check-list .selected-label::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE and Edge */
.dropdown-check-list .selected-label {
    -ms-overflow-style: none;
}

.single-selected-item {
    color: green;
    font-weight: 600;
}

.multi-select-error {
    color: red;
    font-size: smaller;
    font-weight: 100;
}

.dropdown-check-list .input-box-error {
    border: 1px solid red;
    border-radius: 2px;
}

.color-black-label {
    color: black !important;
}

.garage-attr {
    font-size: smaller;
    font-weight: bolder;
    color: #2e6da4;
    border: 1px solid #9b9b9b78;
    margin: 2px;
    padding: 2px;
    border-radius: 5px;
    display: inline-block;
  }

